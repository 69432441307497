import React from 'react';
import { THEMES_DATA_EN, THEMES_DATA_SK, ThemeData } from '../../../constants/2022/themes';
import { ThemeBubble } from './theme-bubble';

import './themes.scss';

export const Themes: React.FC<{ language: 'en' | 'sk' }> = ({ language }) => {
  const themeData = language === 'en' ? THEMES_DATA_EN : THEMES_DATA_SK;

  return (
    <div className="Themes" id="cs-themes">
      <div className="Themes-contentContainer">
      <h1 className="Themes-header">{themeData.header}</h1>
        <div className="Themes-content">
          <div className="Themes-list">
            {themeData.data.map((theme: ThemeData) => (
              <ThemeBubble theme={theme} />
            ))}
          </div>
          
        </div>
      </div>
    </div>
  );
};
