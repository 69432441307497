export type TimeSlot = {
  intervalSK: string;
  intervalEN: string;
  descriptionSK: string;
  descriptionEN: string;
};

export interface Program {
  headerSK: string;
  headerEN: string;
  timeSlots: TimeSlot[];

  endNoteEN?: string;
  endNoteSK?: string;
}

export const PROGRAM_DATA: Program = {
  headerSK: 'Predbežný program',
  headerEN: 'Preliminary program',
  timeSlots: [
    {
      intervalSK: '08:15 – 08:45',
      intervalEN: '08:15am – 08:45am',
      descriptionSK: 'Registrácia',
      descriptionEN: 'Registration',
    },
    {
      intervalSK: '09:00 – 09:30',
      intervalEN: '09:00am – 09:30am',
      descriptionSK: 'Privítanie a úvodné príhovory',
      descriptionEN: 'Welcome and introductory speeches',
    },
    {
      intervalSK: '09:30 – 10:20',
      intervalEN: '09:30am – 10:20am',
      descriptionSK: 'Príležitosti a hrozby pre konzervatívcov v strednej Európe',
      descriptionEN: 'Opportunities and threats for conservatives in Central Europe',
    },
    {
      intervalSK: '10:30 – 12:00',
      intervalEN: '10:30am – 12:00pm',
      descriptionSK: 'Slovenské parlamentné voľby',
      descriptionEN: 'Slovak parliamentary election',
    },
    {
      intervalSK: '12:15 – 13:00',
      intervalEN: '12:15pm – 01:00pm',
      descriptionSK: 'Európske voľby a konzervatívci',
      descriptionEN: 'European elections and conservatives',
    },
    {
      intervalSK: '12:15 – 13:00',
      intervalEN: '12:15pm – 01:00pm',
      descriptionSK: 'Nová generácia slovenských konzervatívcov (2. pódium)',
      descriptionEN: 'New generation of Slovak conservatives (2nd stage)',
    },
    {
      intervalSK: '13:00 – 14:00',
      intervalEN: '01:00pm – 02:00pm',
      descriptionSK: 'Obedná prestávka',
      descriptionEN: 'Lunch break',
    },
    {
      intervalSK: '14:00 – 14:45',
      intervalEN: '02:00pm – 02:45pm',
      descriptionSK: 'Rodinná politika na Slovensku',
      descriptionEN: 'Family policy in Slovakia',
    },
    {
      intervalSK: '14:00 – 14:45',
      intervalEN: '02:00pm – 02:45pm',
      descriptionSK: 'Hospodárska politika na Slovensku (2. pódium)',
      descriptionEN: 'Economic policy in Slovakia (2nd stage)',
    },
    {
      intervalSK: '15:00 – 15:30',
      intervalEN: '03:00pm – 03:30pm',
      descriptionSK: 'Poslanie a pokušenie konzervatívcov na národnej a európskej úrovni',
      descriptionEN: 'Mission and temptation of a conservatives on national and european level',
    },
    {
      intervalSK: '15:30 – 17:00',
      intervalEN: '03:30pm – 05:00pm',
      descriptionSK: 'Ako čeliť novým ideológiam? & Konzervatívny ekosystém: kultúra a inštitúcie (úderné príhovory osobností v TEDx formáte)',
      descriptionEN: 'How to face woke ideologies? & Conservative ecosystem: culture and institutions (TEDx format speeches)',
    },
    {
      intervalSK: '17:15 – 18:15',
      intervalEN: '05:15pm – 06:15pm',
      descriptionSK: 'Poslanie konzervatívnych médií',
      descriptionEN: 'Mission of conservative media',
    },
    {
      intervalSK: '18:15 – 18:30',
      intervalEN: '06:15pm – 06:30pm',
      descriptionSK: 'Záver oficiálnej časti summitu',
      descriptionEN: 'End of the official part of the summit',
    },
    {
      intervalSK: '18:30',
      intervalEN: '06:30pm',
      descriptionSK: 'Recepcia a neformálne diskusie',
      descriptionEN: 'Reception and informal discussions',
    },
  ],
  endNoteEN: '*Unfortunately, Ryszard Legutko apologized due to serious health problems',
  endNoteSK: '*Ryszard Legutko sa žiaľ pre závažné zdravotné problémy ospravedlnil',
};
