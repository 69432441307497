export type GoalData = {
  id: string;
  text: string;
};

export type Goal = {
  header: string;
  data: GoalData[];
};

export const GOALS_DATA_SK: Goal = {
  header: 'Ciele',
  data: [
    {
      id: 'Inšpirovať',
      // text: 'Priviesť do Bratislavy európske konzervatívne osobnosti z politiky a kultúry, aby nám ponúkli inšpiráciu a pomohli prehĺbiť poznanie našich koreňov a spoločensko-politických princípov, ktoré sa od nich odvodzujú.',
      text: 'Inšpirovať konzervatívne prostredie v politike, kultúre a cirkvi na Slovensku.',
    },
    {
      id: 'Zjednocovať',
      // text: 'Vytvoriť priestor a zázemie na stretnutie konzervatívnych lídrov Slovenska z oblasti politiky, cirkví, občianskej spoločnosti, akadémie, biznisu, kultúry a médií. V situácii, keď v Európe čelíme silnejúcim snahám vytlačiť posledné zbytky kresťanského dedičstva na spoločenský okraj, je potrebné usilovať sa o spoluprácu a jednotu v podstatných veciach. Konzervatívny summit má nadstranícky charakter a je jedinečnou príležitosťou na budovanie dobrých vzťahov a atmosféry spolupráce v konzervatívnom spektre.',
      text: 'Prostredníctvom summitu s európskymi konzervatívnymi osobnosťami vytvoriť priestor na stretnutie a hľadanie jednoty v podstatných veciach.',
    },
    {
      id: 'Povzbudiť',
      // text: 'Podporiť odhodlanie a odvahu presadzovať konzervatívne hodnoty. To, že dnes kresťanský pohľad na život a človeka čelí ekonomicko-politicko-mediálnej presile v Európe, nie je dôvod na rezignáciu, ale naopak na plnšie uvedomenie si poslania rozvíjať a chrániť kresťanské dedičstvo.',
      text: 'Povzbudiť k odvahe zasadzovať sa za kresťanské konzervatívne hodnoty.',
    },
  ],
};

export const GOALS_DATA_EN: Goal = {
  header: 'Goals',
  data: [
    {
      id: 'Inspire',
      // text: 'To bring European conservative personalities from politics and culture to Bratislava to offer us inspiration and help deepen the knowledge of our roots and the socio-political principles that derive from them.',
      text: 'To inspire a conservative environment in politics, culture and the church in Slovakia.',
    },
    {
      id: 'Unite',
      // text: 'To create a space and background for a meeting of conservative leaders of Slovakia from the fields of politics, churches, civil society, academia, business, culture and media. In a situation where in Europe we are faced with increasing efforts to push the last remnants of the Christian heritage to the social margins, it is necessary to strive for cooperation and unity in essential matters. The conservative summit has a non-partisan character and is a unique opportunity to build good relations and an atmosphere of cooperation in the conservative spectrum.',
      text: 'To create a space for meeting and seeking unity on essential issues through a summit with European conservative figures.',
    },
    {
      id: 'Encourage',
      // text: 'To support the determination and courage to promote conservative values. The fact that today the Christian view of life and man faces economic-political-media superiority in Europe is not a reason for resignation, but on the contrary for a fuller awareness of the mission to develop and protect the Christian heritage.',
      text: 'To encourage courage to stand up for Christian conservative values.',
    },
  ],
};
