import React from 'react';
import { Speaker } from '../../../constants/2022/speakers';

import { Collapsible23 } from '../collapsible-23/collapsible-23';

import './personal-card-23.scss';


export const PersonalCard23: React.FC<{ speaker: Speaker; language: 'en' | 'sk' }> = ({
  speaker,
  language,
}) => {
  return (
    <div className="PersonalCard23">
      <div className="PersonalCard23-header">
        <img
          className="PersonalCard23-image"
          alt={speaker.name}
          src={process.env.PUBLIC_URL + speaker.image}
        />
        <div className="PersonalCard23-nameContainer">
          <div className="PersonalCard23-name" data-text={speaker.name}>
            <span>{speaker.name}</span>
          </div>
        </div>
      </div>
      <div className="PersonalCard23-text">
        <Collapsible23 language={language} contentLength={language === 'en' ? speaker.textEN.length : speaker.textSK.length} >
          <span>{language === 'en' ? speaker.textEN : speaker.textSK}</span>
        </Collapsible23>
      </div>
    </div>
  );
};
