import React from 'react';
import { THEMES_DATA_EN, THEMES_DATA_SK, ThemeData } from '../../../constants/2023/themes';

import './themes-23.scss';

export const Themes23: React.FC<{ language: 'en' | 'sk' }> = ({ language }) => {
  const themeData = language === 'en' ? THEMES_DATA_EN : THEMES_DATA_SK;

  return (
    <div className="Themes23" id="cs-themes">
      <div className="Themes23-contentContainer">
        <h1 className="Themes23-header">{themeData.header}</h1>
        <div className="Themes23-content">
          <div className="Themes23-list">
            {themeData.data.map((theme: ThemeData) => (
              <span className="Themes23-text">
                {language === 'en'
                  ? 'Slovak and European elections, geopolitics and Central Europe, conservative economic and energy policy, the radicalism of progressivism or the importance of conservative media.'
                  : 'Slovenské aj európske voľby, geopolitika a stredná Európa, konzervatívna hospodárska a energetická politika, radikalizmus progresivizmu či dôležitosť konzervatívnych médií.'}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
