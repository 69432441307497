export type Speaker = {
  image: string;
  name: string;
  textSK: string;
  textEN: string;
};

export interface SpeakerData {
  headerSK: string;
  headerEN: string;
  speakers: Speaker[];
}

export const SPEAKER_DATA: SpeakerData = {
  headerSK: 'Rečníci',
  headerEN: 'Speakers',
  speakers: [
    {
      image: './2021/speaker-images/szijjarto.jpg',
      name: 'Péter Szijjártó',
      textSK: 'Minister zahraničných vecí a obchodu maďarskej vlády',
      textEN: 'Minister of Foreign Affairs and Trade of the Hungarian Government',
    },
    {
      image: './2021/speaker-images/bendova.jpg',
      name: 'Kamila Bendová',
      textSK: 'Česko, matematička, disidentka, aktivistka, manželka Václava Bendu',
      textEN: 'Czech Republic, mathematician, dissident, activist, wife of Václav Benda',
    },
    {
      image: './2021/speaker-images/carnogursky.jpg',
      name: 'Ján Čarnogurský',
      textSK:
        'Advokát, disident, bývalý podpredseda federálnej a predseda slovenskej vlády, exminister spravodlivosti, zakladateľ a bývalý predseda KDH',
      textEN:
        'Lawyer, dissident, former vice-president of the federal government and head of the Slovak government, ex-minister of justice, founder and former chairman of the KDH',
    },
    {
      image: './2021/speaker-images/cunek.jpeg',
      name: 'Jiří Čunek',
      textSK: 'Česko, senátor (KDÚ-ČSL)',
      textEN: 'Czech Republic, senator (KDÚ-ČSL)',
    },
    {
      image: './2021/speaker-images/daniska.jpg',
      name: 'Patrik Daniška',
      textSK: 'Právnik, vedie Inštitút pre ľudské práva a rodinnú politiku',
      textEN: 'Lawyer, head of the Institute for Human Rights and Family Policy',
    },
    {
      image: './2021/speaker-images/engels.jpg',
      name: 'David Engels',
      textSK: 'Belgicko, profesor filozofie a histórie, Institute for Western Affairs',
      textEN: 'Belgium, Professor of Philosophy and History, Institute for Western Affairs',
    },
    {
      image: './2021/speaker-images/ghiletchi.jpg',
      name: 'Valeriu Ghilețchi',
      textSK: 'Moldavsko, podpredseda parlamentu, člen predsedníctva ECPM, baptistický pastor',
      textEN:
        'Moldova, Deputy Speaker of the Parliament, Member of the ECPM Presidium, Baptist Pastor',
    },
    {
      image: './2021/speaker-images/herbich.jpg',
      name: 'Tomasz Herbich',
      textSK: 'Zástupca šéfredaktora týždenníka Teologii Politicznej',
      textEN: 'Deputy editor-in-chief of the Teologii Politicznej weekly',
    },
    {
      image: './2021/speaker-images/krajniak.jpg',
      name: 'Milan Krajniak',
      textSK: 'Minister práce, sociálnych vecí a rodiny SR a podpredseda hnutia Sme rodina',
      textEN:
        'Minister of Labour, Social Affairs and Family of the Slovak Republic and vice-chairman of the We Are Family movement',
    },
    {
      image: './2021/speaker-images/krasnodebski.jpg',
      name: 'Zdzisław Krasnodębski',
      textSK: 'Poľsko, poslanec Európskeho parlamentu (PiS, ECR) - čakáme na potvrdenie',
      textEN:
        'Poland, Member of the European Parliament (PiS, ECR) - we are waiting for confirmation',
    },
    {
      image: './2021/speaker-images/lanczi.png',
      name: 'András Lánczi',
      textSK:
        'Maďarsko, filozof a politológ, profesor na Korvínovej univerzite v Budapešti a rektor Inštitútu pre politické štúdiá',
      textEN:
        'Hungary, philosopher and political scientist, professor at the Corvinus University in Budapest and rector of the Institute for Political Studies',
    },
    {
      image: './2021/speaker-images/majersky.jpg',
      name: 'Milan Majerský',
      textSK:
        'Predseda Prešovského samosprávneho kraja, predseda KDH',
      textEN:
        'Chairman of the Prešov self-governing region, chairman of the KDH',
    },
    {
      image: './2021/speaker-images/mullen.jpeg',
      name: 'Rónán Mullen',
      textSK:
        'Írsko, senátor, líder politickej strany Human Dignity Alliance (HDA)',
      textEN:
        'Ireland, senator, leader of the political party Human Dignity Alliance (HDA)',
    },
    {
      image: './2021/speaker-images/neuwirth.jpg',
      name: 'František Neuwirth',
      textSK:
        'Lekár, konzultant, syn Antona Neuwirtha',
      textEN:
        'Doctor, consultant, son of Anton Neuwirth',
    },
    {
      image: './2021/speaker-images/o_sullivan.jpg',
      name: 'John O’Sullivan',
      textSK:
        'Britský konzervatívny politický komentátor, novinár a spisovateľ, prezident Danube Institute',
      textEN:
        'British conservative political commentator, journalist and writer, president of the Danube Institute',
    },
    {
      image: './2021/speaker-images/puppinck.jpg',
      name: 'Grégor Puppinck',
      textSK:
        'Francúzsko, riaditeľ Európskeho centra pre právo a spravodlivosť (ECLJ) a zástupca Svätej stolice v komisiách pri Rade Európy',
      textEN:
        'France, Director of the European Center for Law and Justice (ECLJ) and Representative of the Holy See in Commissions at the Council of Europe',
    },
    {
      image: './2021/speaker-images/zaborska.jpg',
      name: 'Anna Záborská',
      textSK:
        'Poslankyňa NRSR, predsedníčka Kresťanskej únie',
      textEN:
        'Parliament member of the NRSR, president of the Christian Union',
    },
    {
      image: './2021/speaker-images/zych.jpg',
      name: 'Tymoteusz Zych',
      textSK:
        'Rektor Kolégia Intermarium vo Varšave, viceprezident inštitútu pre právnu kultúru Ordo Iuris',
      textEN:
        'Rector of the Intermarium College in Warsaw, vice president of the Ordo Iuris Institute for Legal Culture',
    },
  ],
};
