import React from 'react';
import { INTRO_DATA_EN, INTRO_DATA_SK } from '../../../constants/2023/intro';
import { FadeInWhenVisible } from '../../../utils/fade-in';

import './intro-24.scss';

export const Intro24: React.FC<{ language: 'en' | 'sk' }> = ({ language }) => {
  const introData = language === 'en' ? INTRO_DATA_EN : INTRO_DATA_SK;

  const intentionText =
    language === 'en' ? (
      <>
        We cordially invite you to the 4th Conservative Summit, which will take place on{' '}
        <b>
          Monday, November 11, 2024 in the Historical Building of the National People's Congress of
          the Slovak Republic on Župne nám. 12 in Bratislava.
        </b>
        <br />
        <br />
        {/*We are also organizing a Conservative Law Conference on November 12.
        <br />
        <br />
         <div className="Intro24-logoContainer">
          <a href="https://www.slh.sk/pravnicka-konferencia">
            <img
              className="Intro24-lawConferenceLogo"
              alt="Conservative Law Conference"
              src="./kpk.webp"
            />
          </a>
        </div>
        <br />
        <br /> */}
        November marks the 35th anniversary of the Velvet Revolution and the social changes that
        brought the restoration of civil and religious liberties and democracy to the Central and
        Eastern Europe. Current domestic and global threats to freedom, security and prosperity, as
        well as new ideologies attacking the true nature and identity of the human person or family,
        however, confirm us in this, that the need for conservatives, Christians and all defenders
        of freedom and common sense is extremely urgent.
        <br />
        <br />
        The Conservative Summit is a gathering of Christian and conservative leaders and
        personalities from politics, culture, churches, civil society, media, academia and business.
        <br />
        <br />
        The Conservative Summit is non-partisan and a unique opportunity to build good relations and
        an atmosphere of cooperation across the conservative spectrum at national, European and
        international level.
        <br />
        <br />
        The Conservative Summit's ambition is to be the respected international ideas conference for
        Christian conservatism.
      </>
    ) : (
      <>
        Srdečne Vás pozývame na 4. Konzervatívny summit, ktorý sa uskutoční v{' '}
        <b>pondelok 11. novembra 2024 v Historickej budove NR SR na Župnom nám. 12 v Bratislave.</b>
        <br />
        <br />
        {/*12.11. organizujeme aj Konzervatívnu právnickú konferenciu.
        <br />
        <br />
         <div className="Intro24-logoContainer">
          <a href="https://www.slh.sk/pravnicka-konferencia">
            <img
              className="Intro24-lawConferenceLogo"
              alt="Conservative Law Conference"
              src="./kpk.webp"
            />
          </a>
        </div>
        <br />
        <br /> */}
        V novembri si pripomíname 35. výročie Nežnej revolúcie a spoločenských zmien, ktoré
        priniesli obnovu občianskych a náboženských slobôd a demokraciu do strednej a východnej
        Európy. Súčasné domáce i globálne ohrozenia slobody, bezpečnosti a prosperity, ako aj nové
        ideológie útočiace na pravú podstatu a identitu človeka či rodiny nás však utvrdzujú v tom,
        že potreba prepájania a spolupráce konzervatívcov, kresťanov a všetkých zástancov slobody a
        zdravého rozumu je mimoriadne naliehavá.
        <br />
        <br />
        Konzervatívny summit je stretnutie kresťanských a konzervatívnych lídrov a osobností z
        oblasti politiky, kultúry, cirkví, občianskej spoločnosti, médií, akademickej obce a
        podnikateľskej sféry.
        <br />
        <br />
        Konzervatívny summit má nadstranícky charakter a je jedinečnou príležitosťou na budovanie
        dobrých vzťahov a atmosféry spolupráce v konzervatívnom spektre na národnej, európskej i
        medzinárodnej úrovni.
        <br />
        <br />
        Ambíciou Konzervatívneho summitu je byť rešpektovanou medzinárodnou ideovou konferenciou
        kresťanského konzervativizmu.
      </>
    );

  return (
    // <FadeInWhenVisible>
    <div className="Intro24" id="cs-introduction">
      <img className="Intro24-image" alt="intro" src="./hands_up.jpg" />
      <div className="Intro24-contentContainer">
        <div className="Intro24-content">
          <div className="Intro24-intention">
            <h1 className="Intro24-header">{introData.header}</h1>
            <div className="Intro24-text">
              <p>{intentionText}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </FadeInWhenVisible>
  );
};
