import React from 'react';
import { ThemeData } from '../../../constants/2022/themes';

import './theme-bubble.scss';

export const ThemeBubble: React.FC<{ theme: ThemeData }> = ({ theme }) => {
  return (
    <div className="ThemeBubble">
      <div className="ThemeBubble-textContainer">
        <div className="ThemeBubble-text">{theme.text}</div>
      </div>
    </div>
  );
};
