export type Text = {
  text: string;
};

export interface Contact {
  header: Text;
}

export const CONTACT_DATA_SK: Contact = {
  header: {
    text: 'Kontakt',
  }
};

export const CONTACT_DATA_EN: Contact = {
  header: {
    text: 'Contact',
  }
};
