import React from 'react';

import { ABOUT_US_DATA_EN, ABOUT_US_DATA_SK } from '../../../constants/2021/about-us';

import './organisers-21.scss';

export const Organisers21: React.FC<{ language: 'en' | 'sk' }> = ({ language }) => {
  const aboutUsData = language === 'en' ? ABOUT_US_DATA_EN : ABOUT_US_DATA_SK;

  const mainOrganizerText =
    language === 'en' ? (
      <>
        <b>The Ladislav Hanus Fellowship (ILH)</b> since 2003, it has been organizing
        formal-academic programs for Christian leaders in culture and public life, and for almost
        ten years it has been organizing Hanus Days as a city cultural-academic festival about the
        relationship between Christianity and contemporary society in Bratislava and Košice. This
        year, it also took over the publication and creation of an intellectual magazine for
        Christian culture - VERBUM.
      </>
    ) : (
      <>
        <b>Spoločenstvo Ladislava Hanusa (SLH)</b> už od roku 2003 organizuje formačno-akademické
        programy pre kresťanských lídrov v kultúre a verejnom živote a už takmer desať rokov
        organizuje Hanusove dni ako mestský kultúrno-akademický festival o vzťahu kresťanstva a
        súčasnej spoločnosti v Bratislave a Košiciach. Tento rok tiež prevzalo vydávanie a tvorbu
        intelektuálneho časopisu pre kresťanskú kultúru - VERBUM.
      </>
    );

  const coOrganizerText =
    language === 'en' ? (
      <>
        <b>The Rodina Institute</b>, which focuses on supporting reflection and discussion on
        fundamental issues of public life in the spirit of the Judeo-Christian tradition.
      </>
    ) : (
      <>
        <b>Inštitút Rodina</b>, ktorý sa zameriava na podporu uvažovania a diskusie o zásadných
        otázkach verejného života v duchu židovsko-kresťanskej tradície.
      </>
    );

  const secondCoOrganizerText =
    language === 'en' ? (
      <>
        <b>Collegium Intermarium</b> aims to restore the classical idea of the university and to
        create an academic community deeply rooted in European tradition and culture. Education and
        research is conducted with respect for the achievements of previous generations and with
        hope and optimism to the future.
      </>
    ) : (
      <>
        <b>Collegium Intermarium</b> si kladie za cieľ obnoviť klasickú myšlienku univerzity a
        vytvoriť akademickú obec hlboko zakorenenú v európskej tradícii a kultúre. Vzdelávanie a
        výskum prebiehajú s rešpektom k úspechom predchádzajúcich generácií a s nádejou a optimizmom
        do budúcnosti.
      </>
    );

  return (
    <div className="Organisers21" id="cs-about-us">
      <h1 className="Organisers21-header">{aboutUsData.header}</h1>
      <div className="Organisers21-container">
        <div className="Organisers21-announcementContainer">
          <h3>{aboutUsData.mainOrganizer.header}</h3>
          <div className="Organisers21-logoContainer">
            <a href="https://www.slh.sk">
              <img
                className="Organisers21-logo"
                alt={
                  language === 'en' ? 'Ladislav Hanus Fellowship' : 'Spoločenstvo Ladislava Hanusa'
                }
                src={`./slh.jpg`}
              />
            </a>
          </div>
          <div className="Organisers21-text">{mainOrganizerText}</div>
          <div className="Organisers21-text">{coOrganizerText}</div>
          <div className="Organisers21-text">{secondCoOrganizerText}</div>
        </div>
      </div>
    </div>
  );
};
