export type Text = {
  text: string;
};

export type CountDownTime = {
  cdDay: Text;
  cdHour: Text;
  cdMinute: Text;
  cdSecond: Text;
};

export interface CountDown {
  header: Text;
  time: CountDownTime;
  info: Text;
}

export const COUNT_DOWN_DATA_SK: CountDown = {
  header: {
    text: 'Zostávajúci čas',
  },
  time: {
    cdDay: {
      text: 'dni',
    },
    cdHour: {
      text: 'hodiny',
    },
    cdMinute: { text: 'minúty' },
    cdSecond: { text: 'sekundy' },
  },
  info: {
    text: 'Viac informácií už čoskoro...',
  },
};

export const COUNT_DOWN_DATA_EN: CountDown = {
  header: {
    text: 'Remaining time',
  },
  time: {
    cdDay: {
      text: 'days',
    },
    cdHour: {
      text: 'hours',
    },
    cdMinute: { text: 'minutes' },
    cdSecond: { text: 'seconds' },
  },
  info: {
    text: 'More information coming soon...',
  },
};
