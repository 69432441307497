import { Header } from '../components/UI/header/header';
import { Speakers24 } from '../components/2024/speakers-24/speakers-24';
import { Intro24 } from '../components/2024/introduction-24/intro-24';
import { CountDown } from '../components/2023/count-down/count-down';
// import { Advertisement } from './../components/advertisement/advertisement';
import { Gallery24 } from '../components/2024/gallery-24/gallery-24';
// import { Program23 } from '../components/2023/program-23/program-23';
import { Contact } from '../components/UI/contact/contact';
import { Registration24 } from '../components/2024/registration-24/registration-24';
import { Organisers } from '../components/UI/organisers/organisers';
import { useCountdown } from '../hooks/use-count-down';
import { Goals24 } from '../components/2024/goals-24/goals-24';
import { Sponsors24 } from '../components/2024/sponsors/sponsors';
import { Themes24 } from '../components/2024/themes-24/themes-24';
import { MENU_DATA } from '../constants/2024/menu';
import {
  GALLERY_IMAGES,
  // GALLERY_VIDEOS,
  GALLERY_DATA_EN,
  GALLERY_DATA_SK,
} from '../constants/2024/gallery';

export const SummitPage2024: React.FC<{ markedLanguage: 'en' | 'sk'; onLanguageChange: any }> = ({
  markedLanguage,
  onLanguageChange,
}) => {
  const COUNT_DOWN_FINISH = '2024-11-11';
  const myCountdown = useCountdown(COUNT_DOWN_FINISH);

  return (
    <>
      <Header
        language={markedLanguage}
        onLanguageChange={onLanguageChange}
        site="2024"
        menuData={MENU_DATA}
        showSloganSlide
      />
      <CountDown countDown={myCountdown} language={markedLanguage} />
      <Intro24 language={markedLanguage} />
      <Goals24 language={markedLanguage} />
      {/* <Program23 language={markedLanguage} /> */}
      <Speakers24 language={markedLanguage} />
      <Themes24 language={markedLanguage} />
      <Registration24 language={markedLanguage} />
      <Gallery24
        language={markedLanguage}
        images={GALLERY_IMAGES}
        data={markedLanguage === 'en' ? GALLERY_DATA_EN : GALLERY_DATA_SK}
      />
      <Sponsors24 language={markedLanguage} />
      <Organisers language={markedLanguage} showCoorganizers={false} />
      <Contact language={markedLanguage} />
    </>
  );
};
