import React from 'react';

import { SPEAKER_DATA } from '../../../constants/2024/speakers';
import { PersonalCard24 } from '../../2024/personal-card-24/personal-card-24';

import './speakers-24.scss';

export const Speakers24: React.FC<{ language: 'en' | 'sk' }> = ({ language }) => {
  return (
    <div className="Speakers24" id="cs-speakers">
      <h1 className="Speakers24-header">
        {language === 'en' ? SPEAKER_DATA.headerEN : SPEAKER_DATA.headerSK}
      </h1>
      <div className="Speakers24-cards">
        {SPEAKER_DATA.speakers.map((speaker) => (
          <PersonalCard24 key={speaker.name} speaker={speaker} language={language} />
        ))}
      </div>
      <div className="Speakers24-announcement">
        {language === 'en'
          ? 'and many others...'
          : 'a mnohí ďalší...'}
      </div>
    </div>
  );
};
