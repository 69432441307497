export type ThemeData = {
  text: string;
};

export type Theme = {
  header: string;
  data: ThemeData[];
};

export const THEMES_DATA_SK: Theme = {
  header: 'Témy 2024',
  data: [
    {
      text: 'USA 2025: Impérium progresivizmu alebo zdravého rozumu?',
    },
    {
      text: 'Ako duchovne pozdvihnúť Európu?',
    },
    {
      text: 'Ako urobiť Európu opäť skvelou a významnou?',
    },
    {
      text: 'Konzervatívny ekosystém či paralelná polis? (vzdelávanie a formácia, inštitúcie, kultúra, médiá, partnerstvá, ekonomika, ...)',
    },
    {
      text: 'Aktuálne domáce témy z konzervatívnej perspektívy',
    },
  ],
};

export const THEMES_DATA_EN: Theme = {
  header: 'Themes 2024',
  data: [
    {
      text: 'USA 2025: Empire of Progressivism or Common Sense?',
    },
    {
      text: 'How to lift up Europe spiritually?',
    },
    {
      text: 'How to make Europe great and important again?',
    },
    {
      text: 'Conservative ecosystem or parallel polis? (education and formation, institutions, culture, media, partnerships, economy, ...)',
    },
    {
      text: 'Current native topics from a conservative perspective',
    },
  ],
};
