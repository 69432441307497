import React from 'react';
import { GoalData } from '../../../constants/2023/goals';

import './goal-card.scss';

export const GoalCard: React.FC<{ goal: GoalData }> = ({ goal }) => {
  return (
    <div className="GoalCard">
      <div className="GoalCard-id">{goal.id}</div>
      <div className="GoalCard-textContainer">
        <div className="GoalCard-text">{goal.text}</div>
      </div>
    </div>
  );
};
