import React from 'react';

import './tab-24.scss';

type Props = {
  title: string;
  children: any;
};

export const Tab24: React.FC<Props> = ({ children }) => {
  return (
    <div className="Tab24">
      {children}
    </div>
  );
};
