import React from 'react';
import { THEMES_DATA_EN, THEMES_DATA_SK, ThemeData } from '../../../constants/2024/themes';

import './themes-24.scss';

export const Themes24: React.FC<{ language: 'en' | 'sk' }> = ({ language }) => {
  const themeData = language === 'en' ? THEMES_DATA_EN : THEMES_DATA_SK;

  return (
    <div className="Themes24" id="cs-themes">
      <div className="Themes24-contentContainer">
        <h1 className="Themes24-header">{themeData.header}</h1>
        <div className="Themes24-content">
          <div className="Themes24-list">
            {themeData.data.map((theme: ThemeData) => (
              <span className="Themes24-text">
                {theme.text}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
