import React from 'react';

import './mobile-menu-button.scss';

export const MobileMenuButton: React.FC<{ onClick: any }> = ({ onClick }) => {
  return (
    <button className="MobileMenuButton" onClick={onClick}>
      <span className="MobileMenuButton-stripe"></span>
      <span className="MobileMenuButton-stripe"></span>
      <span className="MobileMenuButton-stripe"></span>
    </button>
  );
};
