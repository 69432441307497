import React from 'react';

import { ABOUT_US_DATA_EN, ABOUT_US_DATA_SK } from '../../../constants/2022/about-us';
import { ReactComponent as IlhSkLogo } from './../../assets/logo-ILH-sk.svg';
import { ReactComponent as IlhEnLogo } from './../../assets/logo-ILH-en.svg';

import './organisers.scss';

export const Organisers: React.FC<{ language: 'en' | 'sk'; showCoorganizers: boolean }> = ({
  language,
  showCoorganizers,
}) => {
  const aboutUsData = language === 'en' ? ABOUT_US_DATA_EN : ABOUT_US_DATA_SK;

  const mainOrganizerText =
    language === 'en' ? (
      <>
        <b>The Ladislav Hanus Institute (ILH)</b>, which comes from Ladislav Hanus Fellowship. Its
        vision is to combine faith with reason, to cultivate Christian culture, and to courageously
        promote conservative principles in public life.
      </>
    ) : (
      <>
        <b>Inštitút Ladislava Hanusa (ILH)</b>, ktorý vychádza z radov Spoločenstva Ladislava
        Hanusa. Jeho víziou je spájať vieru s rozumom, rozvíjať kresťanskú kultúru a odvážne
        presadzovať konzervatívne princípy vo verejnom živote.
      </>
    );

  const coOrganizerText =
    language === 'en' ? (
      <>
        <b>The Rodina Institute</b>, which focuses on supporting reflection and discussion on
        fundamental issues of public life in the spirit of the Judeo-Christian tradition.
      </>
    ) : (
      <>
        <b>Inštitút Rodina</b>, ktorý sa zameriava na podporu uvažovania a diskusie o zásadných
        otázkach verejného života v duchu židovsko-kresťanskej tradície.
      </>
    );

  return (
    <div className="Organisers" id="cs-about-us">
      <h1 className="Organisers-header">{aboutUsData.header}</h1>
      <div className="Organisers-container">
        <div className="Organisers-announcementContainer">
          {/* <p>
            {language === 'en'
              ? 'More information coming soon...'
              : 'Viac informácií už čoskoro...'}
          </p> */}
          <h3>{aboutUsData.mainOrganizer.header}</h3>
          <div className="Organisers-logoContainer">
            <a href="https://www.slh.sk/ilh">
              {language === 'en' ? (
                <IlhEnLogo className="Organisers-logo" width="250px" />
              ) : (
                <IlhSkLogo className="Organisers-logo" width="250px" />
              )}
              {/* <img
                className="Organisers-logo"
                alt={language === 'en' ? 'Ladislav Hanus Institute' : 'Inštitút Ladislava Hanusa'}
                src={`./2023/about-us-images/logo_ILH_${language}_white.png`}
              /> */}
            </a>
          </div>
          <div className="Organisers-text">{mainOrganizerText}</div>
          {showCoorganizers && (
            <>
              <h3>{aboutUsData.coOrganizer.header}</h3>
              <div className="Organisers-text">{coOrganizerText}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
