import React from 'react';

import './speakers.scss';
import { SPEAKER_DATA } from '../../../constants/2023/speakers';
import { PersonalCard23 } from '../personal-card-23/personal-card-23';

export const Speakers: React.FC<{ language: 'en' | 'sk' }> = ({ language }) => {
  return (
    <div className="Speakers" id="cs-speakers">
      <h1 className="Speakers-header">
        {language === 'en' ? SPEAKER_DATA.headerEN : SPEAKER_DATA.headerSK}
      </h1>
      <div className="Speakers-cards">
        {SPEAKER_DATA.speakers.map((speaker) => (
          <PersonalCard23 key={speaker.name} speaker={speaker} language={language} />
        ))}
      </div>
      <div className="Speakers-announcement">
        {language === 'en'
          ? 'and more...'
          : 'a ďalší...'}
      </div>
    </div>
  );
};
