export type ThemeData = {
  text: string;
};

export type Theme = {
  header: string;
  data: ThemeData[];
};

export const THEMES_DATA_SK: Theme = {
  header: 'Témy 2023',
  data: [
    {
      text: 'Slovenské aj európske voľby, geopolitika a stredná Európa, konzervatívna hospodárska a energetická politika, radikalizmus progresivizmu či dôležitosť konzervatívnych médií.',
    },
    // {
    //   text: 'Konzervatívna vízia Európy, spolupráca konzervatívnych strán a aktuálne výzvy v rámci EU, vízia a poslanie strednej Európy, jej pamäť a identita, spoločné záujmy',
    // },
    // {
    //   text: 'Rodinná politika a s ňou súvisiace výzvy ako je demografia, inflácia, energetická kríza a ďalšie...',
    // },
  ],
};

export const THEMES_DATA_EN: Theme = {
  header: 'Themes 2023',
  data: [
    {
      text: 'Slovak and European elections, geopolitics and Central Europe, conservative economic and energy policy, the radicalism of progressivism or the importance of conservative media.',
    },
    // {
    //   text: 'Conservative vision of Europe, cooperation of conservative parties and current challenges within the EU, vision and mission of Central Europe, its memory and identity, common interests',
    // },
    // {
    //   text: 'Family policy and related challenges such as demography, inflation, energy crisis and more...',
    // },
  ],
};
