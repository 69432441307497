export type Speaker = {
  image: string;
  name: string;
  textSK: string;
  textEN: string;
};

export interface SpeakerData {
  headerSK: string;
  headerEN: string;
  speakers: Speaker[];
}

export const SPEAKER_DATA: SpeakerData = {
  headerSK: 'Potvrdení rečníci',
  headerEN: 'Confirmed speakers',
  speakers: [
    {
      image: './2023/speakers-images/dreher.jpg',
      name: 'Rod Dreher',
      textSK:
        'Konzervatívny mysliteľ, spisovateľ a publicista, autor kníh Benediktova voľba a Neži v lži. Jeho kniha Benediktova voľba sa považuje za najdôležitejšiu knihu dekády (David Brooks) a jeho posledná kniha Neži v lži, v ktorej pre americké publikum prerozprával skúsenosti disidentov v boji s komunistickou totalitou sa stala celosvetovým bestsellerom. Znalec republikánskeho prostredia, priateľ kandidáta na viceprezidenta J.D. Vance-a. Aktuálne pôsobí v Danube Institute.',
      textEN:
        'Conservative thinker, writer and publicist, author of the books Benedict\'s Choice and Neží v lži. His book Benedict\'s Choice is considered the most important book of the decade (David Brooks) and his last book, Don\'t Live a Lie, in which he retold the experiences of dissidents in the fight against communist totality for an American audience, became a worldwide bestseller. An expert in the Republican environment, a friend of the vice-presidential candidate J.D. Vance. He currently works at the Danube Institute.',
    },
    {
      image: './2024/speaker-images/dostal.jpg',
      name: 'Ondřej Dostál',
      textSK:
        'Nezávislý poslanec Európskeho parlamentu, člen Európskeho kresťanského politického hnutia (ECPM). Je členom parlamentného výboru pre životné prostredie, verejné zdravie a bezpečnosť potravín (ENVI) a členom delegácie pre vzťahy s krajinami juhovýchodnej Ázie (DASE). Viac ako dvadsať rokov je hlasom volajúcim po solidarite v prístupe k zdravotnej starostlivosti v Česku a obhajcom práv pacientov, informovaného súhlasu a implementácie Oviedskeho dohovoru. Jeho odborné znalosti ako právnika zahŕňajú zdravotnú politiku, bioetiku, práva pacientov a medicínske právo.',
      textEN:
        'Independent Member of the European Parliament, member of the European Christian Political Movement (ECPM). He is a member of the Parliament\'s Committee on Environment, Public Health and Food Safety (ENVI) and a member of the Delegation for Relations with South-East Asian Countries (DASE). For more than twenty years he has been a voice calling for solidarity in access to healthcare in the Czech Republic and an advocate for patients\' rights, informed consent and the implementation of the Oviedo Convention. His expertise as a lawyer includes health policy, bioethics, patients\' rights and medical law.',
    },
    {
      image: './2021/speaker-images/engels.jpg',
      name: 'David Engels',
      textSK:
        'Historik a filozof histórie. Výskumný pracovník na viacerých európskych univerzitách a prednášajúci na ICES (Katolícky inštitút vo Vendée). Spisovateľ a publicista v rôznych európskych publikáciách vrátane European Conservative. V roku 2024 mu vyšla nová kniha Obrana civilizovanej Európy - Krátke pojednanie o hesperializme.',
      textEN:
        'Historian and philosopher of history. Researcher at several European universities and lecturer at ICES (Catholic Institute of Vendée). Writer and columnist in various European publications including the European Conservative. His new book, The Defence of Civilised Europe - A Short Treatise on Hesperianism, was published in 2024.',
    },
    {
      image: './2024/speaker-images/christiaan-alting.jpg',
      name: 'Christiaan Alting von Geusau',
      textSK:
        'Právnik, akademik, vedie Medzinárodnú sieť katolíckych zákonodarcov (ICLN), ktorá kresťanským politikom poskytuje formáciu vo viere, vzdelávacie programy a možnosti nadväzovania kontaktov. Ako riaditeľ spoločnosti Ambrose Advice e.U. poskytuje strategické poradenstvo a koučing v oblasti vedenia vysokopostaveným politikom, vedúcim pracovníkom a mimovládnym organizáciám na celom svete. Dr. von Geusau je emeritným rektorom a profesorom filozofie práva a vzdelávania na Katolíckej univerzite ITI v Rakúsku a čestným profesorom práva na Universidad San Ignacio de Loyola v Peru. Je ženatý a je otcom piatich detí.',
      textEN:
        'A lawyer, academic, he leads the International Catholic Legislators Network (ICLN), which provides faith formation, educational programs and networking opportunities for Christian politicians. As Director of Ambrose Advice e.U., he provides strategic advice and leadership coaching to high-level politicians, executives and NGOs around the world. Dr. von Geusau is Rector Emeritus and Professor of Philosophy of Law and Education at the Catholic University ITI in Austria and Honorary Professor of Law at the Universidad San Ignacio de Loyola in Peru. He is married and the father of five children.',
    },
    {
      image: './2024/speaker-images/pappin.jpg',
      name: 'Gladden Pappin',
      textSK:
        'Prezident Maďarského inštitútu pre medzinárodné záležitosti. Absolvent Harvardu, pôsobil ako hosťujúci akademik na Mathias Corvinus Collegium, ako aj mimoriadny profesor politiky na univerzite v Dallase. Je spoluzakladateľom a zástupcom šéfredaktora časopisu American Affairs, ako aj spoluzakladateľom časopisu Postliberal Order. Od roku 2017 je rytierom magisterskej milosti Suverénneho vojenského maltézskeho rádu a od roku 2021 rytierom za zásluhy v Posvätnom vojenskom konštantínopolskom ráde svätého Juraja.',
      textEN:
        'President of the Hungarian Institute for International Affairs. A graduate of Harvard, he has served as a visiting scholar at the Mathias Corvinus Collegium, as well as an adjunct professor of politics at the University of Dallas. He is co-founder and deputy editor-in-chief of American Affairs, as well as co-founder of Postliberal Order. He has been a Knight of the Magisterial Grace of the Sovereign Military Order of Malta since 2017 and a Knight of Merit in the Sacred Military Order of St. George of Constantinople since 2021.',
    },
    {
      image: './2024/speaker-images/roets.jpeg',
      name: 'Ernst Roets',
      textSK:
        'Právnik, občiansky aktivista, autor a režisér. Je výkonným riaditeľom juhoafrickej nadácie Afrikaner Foundation a vedúci politickej stratégie hnutia Solidarity Movement združujúcej  takmer 50 inštitúcii s vyše 570 000 členmi. Špecializuje sa na rozvoj komunitných inštitúcii nezávislých od štátu.',
      textEN:
        'Lawyer, civil activist, author and filmmaker. He is Executive Director of the Afrikaner Foundation in South Africa and Head of Political Strategy for the Solidarity Movement, a movement of nearly 50 institutions with over 570,000 members. He specialises in the development of community institutions independent of the state.',
    },
    {
      image: './2024/speaker-images/szalai.jpg',
      name: 'Zoltán Szalai',
      textSK:
        'Germanista, kultúrny historik. Od roku 2009 pôsobí ako generálny riaditeľ Mathias Corvinus Collegium a od roku 2017 ako šéfredaktor spravodajského časopisu Mandiner. Pod jeho vedením sa MCC rozrástlo na jednu z najväčších európskych vzdelávacích inštitúcií a výskumných centier. Dr. Szalai je autorom rozsiahlych publikácií na rôzne témy, pričom sa zameriava najmä na filozofiu Vilmosa Szilasiho a Martina Heideggera. Je ženatý a je otcom dvoch synov.',
      textEN:
        'Germanist, cultural historian. Since 2009 he has been the CEO of the Mathias Corvinus Collegium and since 2017 the editor-in-chief of the news magazine Mandiner. Under his leadership, MCC has grown into one of Europe\'s largest educational institutions and research centers. Dr. Szalai is the author of extensive publications on a variety of topics, with a particular focus on the philosophy of Vilmos Szilasi and Martin Heidegger. He is married and the father of two sons.',
    },
    {
      image: './2024/speaker-images/whitehead.jpg',
      name: 'Daniel Whitehead',
      textSK:
        'Pôsobil v tíme guvernéra Rona DeSantisa. Pracoval ako úradník na dvoch federálnych odvolacích súdoch ako aj pre Ministerstvo zdravotníctva a sociálnych služieb USA a Ministerstvo pre záležitosti veteránov USA. Je členom John Marshall Fellow of the Claremont Institute a členom James Wilson Institute. Ako rodený Floridčan navštevoval Ave Maria School of Law a Ave Maria University, kde vyštudoval klasické jazyky a literatúru.',
      textEN:
        'He served on Governor Ron DeSantis\' team. He has clerked for two federal appellate courts as well as for the U.S. Department of Health and Human Services and the U.S. Department of Veterans Affairs. He is a John Marshall Fellow of the Claremont Institute and a member of the James Wilson Institute. A native Floridian, he attended Ave Maria School of Law and Ave Maria University, where he majored in classical languages and literature.',
    },
  ],
};