import React, { ReactElement, useState } from 'react';
import { TabTitle } from './tab-title';
import './tabs.scss';

type Props = {
  children: ReactElement[] | null;
};

export const Tabs: React.FC<Props> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="Tabs">
      <ul className="Tabs-list">
        {children && children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        ))}
      </ul>
      {children && <div className="Tabs-tabs">{children[selectedTab]}</div>}
    </div>
  );
};
