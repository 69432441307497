import classnames from 'classnames';
import React from 'react';
import { MenuButton } from '../menu-button/menu-button';
import { MenuData } from '../../../types/menu';

import './menu.scss';

export const Menu: React.FC<{
  language: 'en' | 'sk';
  position: string;
  onClick?: any;
  isMenuFixed: boolean;
  menuData: MenuData;
}> = ({ language, position, onClick, isMenuFixed, menuData }) => {
  if (position !== 'main-page') {
    return (
      <div className="Menu-sideDrawer">
        <ul className="Menu-list">
          {menuData.menuButtons.map((button) => (
            <MenuButton
              key={button.nameEN}
              name={language === 'en' ? button.nameEN : button.nameSK}
              link={button.link}
              onClick={onClick}
              smoothScrolling={false}
              isMenuFixed={isMenuFixed}
            />
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div
      className={classnames(`Menu-mainPage`, {
        'Menu-mainPage--notFixed': !isMenuFixed,
      })}
    >
      {menuData.menuButtons.map((button) => (
        <MenuButton
          key={button.nameEN}
          name={language === 'en' ? button.nameEN : button.nameSK}
          link={button.link}
          smoothScrolling={true}
          isMenuFixed={isMenuFixed}
        />
      ))}
    </div>
  );
};
