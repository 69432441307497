export type ThemeData = {
  text: string;
};

export type Theme = {
  header: string;
  data: ThemeData[];
};

export const THEMES_DATA_SK: Theme = {
  header: 'Témy 2022',
  data: [
    {
      text: 'Vojna na Ukrajine a rozličné postoje k nej tak v rámci strednej Európy, ako aj konzervatívneho spektra',
    },
    {
      text: 'Konzervatívna vízia Európy, spolupráca konzervatívnych strán a aktuálne výzvy v rámci EU, vízia a poslanie strednej Európy, jej pamäť a identita, spoločné záujmy',
    },
    {
      text: 'Rodinná politika a s ňou súvisiace výzvy ako je demografia, inflácia, energetická kríza a ďalšie...',
    },
  ],
};

export const THEMES_DATA_EN: Theme = {
  header: 'Themes 2022',
  data: [
    {
      text: 'The war in Ukraine and different attitudes towards it both within Central Europe and the conservative spectrum',
    },
    {
      text: 'Conservative vision of Europe, cooperation of conservative parties and current challenges within the EU, vision and mission of Central Europe, its memory and identity, common interests',
    },
    {
      text: 'Family policy and related challenges such as demography, inflation, energy crisis and more...',
    },
  ],
};
