import React, { useState } from 'react';

import Router from './routing/router';

import './App.scss';

const App: React.FC = () => {
  const language = window.location.href.includes('conservativesummit.eu') ? 'en' : 'sk';

  const [markedLanguage, setMarkedLanguage] = useState<'en' | 'sk'>(language);

  const handleLanguageChange = () => {
    return markedLanguage === 'en' ? setMarkedLanguage('sk') : setMarkedLanguage('en');
  };

  return (
    <div className="App">
      <Router markedLanguage={markedLanguage} onLanguageChange={handleLanguageChange} />
    </div>
  );
};

export default App;
