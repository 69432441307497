import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import classnames from 'classnames';

import { Menu } from '../menu/menu';
import { MobileMenuButton } from '../mobile-menu-button/mobile-menu-button';
import { Backdrop } from '../back-drop/backdrop';
import { SideDrawer } from '../side-drawer/side-drawer';
import { SloganSlide } from '../slogan-slide/slogan-slide';
import { SiteSwitch } from '../site-switch/site-switch';
import { MenuData } from '../../../types/menu';
import { ReactComponent as EnglishIcon } from './gb.svg';
import { ReactComponent as SlovakIcon } from './sk.svg';
import { Banner } from '../banner/banner';

import './header.scss';

export const Header: React.FC<{
  language: 'en' | 'sk';
  onLanguageChange: any;
  site: string;
  menuData: MenuData;
  showSloganSlide: boolean;
}> = ({ language, onLanguageChange, site, menuData, showSloganSlide }) => {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [small, setSmall] = useState(false);
  const [logoHeight, setLogoHeight] = useState({});

  useEffect(() => {
    const onScroll = (e: any) => {
      const bigLogo = document.getElementById('myBigLogo');
      const sloganSlide = document.getElementById('mySloganSlide');
      const logoHeight = bigLogo?.offsetHeight;
      const sloganSlideHeight = sloganSlide?.offsetHeight;
      setScrollTop(e?.target?.documentElement?.scrollTop);
      setScrolling(e?.target?.documentElement?.scrollTop > scrollTop);
      if (logoHeight && sloganSlideHeight !== undefined) {
        setSmall(window.pageYOffset > logoHeight + sloganSlideHeight);
        setLogoHeight(logoHeight);
      } else if (logoHeight) {
        setSmall(window.pageYOffset > logoHeight);
        setLogoHeight(logoHeight);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop, small, logoHeight]);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <React.Fragment>
      <div className="Header">
        {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
        <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
          <nav>
            <Menu
              language={language}
              position="side-drawer"
              onClick={closeDrawerHandler}
              isMenuFixed={small}
              menuData={menuData}
            />
          </nav>
        </SideDrawer>
        <div className="Header-bigLogoContainer" id="myBigLogo">
          <img className="Header-bigLogo" alt="logo" src={`./logo_KS_${site}_${language}.png`} />
        </div>
        {showSloganSlide && (
          <SloganSlide id="mySloganSlide" language={language} isMenuFixed={small} />
        )}
        <div
          className={classnames(`Header-stickyContainer`, {
            'Header-stickyContainer-fixed': small,
          })}
          id="myHeader"
        >
          <div
            className={classnames(`Header-smallLogoContainer`, {
              'Header-smallLogoContainer--hidden': !small,
            })}
            onClick={handleLogoClick}
          >
            <div className="Header-smallLogo">
              <img className="Header-bigLogo" alt="logo" src={`./logo_KS_${site}_${language}.png`} />
            </div>
          </div>
          <div className="Header-menuContainer">
            <MobileMenuButton onClick={openDrawerHandler} />
            <Menu
              language={language}
              position="main-page"
              isMenuFixed={small}
              menuData={menuData}
            />
            <Switch
              className="Header-languageSwitch"
              onChange={onLanguageChange}
              checked={language === 'en' ? true : false}
              handleDiameter={16}
              width={70}
              borderRadius={20}
              onColor="#1bace2"
              offColor="#585858"
              activeBoxShadow="0px 0px 1px 2px #fffc35"
              uncheckedIcon={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    fontSize: 12,
                    color: 'white',
                    paddingRight: 0,
                  }}
                >
                  <span>EN</span>
                  <EnglishIcon className="Header-switchIcon" />
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    fontSize: 12,
                    color: 'white',
                    paddingLeft: 0,
                  }}
                >
                  <SlovakIcon className="Header-switchIcon" />
                  <span>SK</span>
                </div>
              }
            />
          </div>
          <SiteSwitch language={language} site={site} />
          <Banner language={language}/>
        </div>
      </div>
    </React.Fragment>
  );
};
