export type Speaker = {
  image: string;
  name: string;
  textSK: string;
  textEN: string;
};

export interface SpeakerData {
  headerSK: string;
  headerEN: string;
  speakers: Speaker[];
}

export const SPEAKER_DATA: SpeakerData = {
  headerSK: 'Rečníci',
  headerEN: 'Speakers',
  speakers: [
  {
    image: './speaker-images/1sp-heger.jpg',
    name: 'Eduard Heger',
    textSK: 'Predseda vlády Slovenskej republiky',
    textEN: 'Prime Minister of the Slovak Republic',
  },
  {
    image: './speaker-images/2sp-morawiecki.jpg',
    name: 'Mateusz Morawiecki',
    textSK: 'Predseda vlády Poľskej republiky',
    textEN: 'Prime Minister of the Republic of Poland',
  },
  {
    image: './speaker-images/3sp-varga.jpg',
    name: 'Judit Varga',
    textSK: 'Ministerka spravodlivosti Maďarska',
    textEN: 'Minister of Justice of Hungary',
  },
  {
    image: './speaker-images/4sp-krajniak.jpg',
    name: 'Milan Krajniak',
    textSK: 'Minister práce, sociálnych vecí a rodiny SR',
    textEN: 'Minister of Labour, Social Affairs and Family of the SR',
  },
  {
    image: './speaker-images/5sp-zaborska.jpg',
    name: 'Anna Záborská',
    textSK: 'Splnomocnenec vlády SR pre ochranu slobody vierovyznania alebo presvedčenia, poslankyňa NR SR',
    textEN: 'Representative of the Government of the Slovak Republic for the protection of freedom of religion or belief, member of the National Assembly of the Slovak Republic'
  },
  {
    image: './speaker-images/6sp-lexmann.jpg',
    name: 'Miriam Lexmann',
    textSK: 'Poslankyňa Európskeho parlamentu, odborníčka na zahraničnú politiku',
    textEN: 'Member of the European Parliament, expert on foreign policy'
  },
  {
    image: './speaker-images/babinetz.jpg',
    name: 'Piotr Babinetz',
    textSK: 'Predseda Výboru pre kultúru a médiá a člen Národnej mediálnej rady, poslanec Sejmu',
    textEN: 'Chairman of the Culture and Media Committee and a Member of National Media Council, Member of Sejm'
  },
  // {
  //   image: './speaker-images/7sp-kuchcinski.jpg',
  //   name: 'Marek Kuchciński',
  //   textSK: 'Poslanec poľského sejmu a predseda zahraničného výboru',
  //   textEN: 'Member of the Parliament of Poland and Chairman of the Foreign Affairs Committee',
  // },
  {
    image: './speaker-images/8sp-cichocki.jpg',
    name: 'Prof. Marek Cichocki',
    textSK: 'Poľský profesor na Collegium Civitas, šéfredaktor Teologia Polityczna, popredný odborník na vzťahy strednej Európy s Nemeckom a Ruskom',
    textEN: 'Polish professor at Collegium Civitas, editor-in-chief of Teologia Polityczna, leading expert on Central Europe\'s relations with Germany and Russia',
  },
  {
    image: './speaker-images/9sp-orban.jpg',
    name: 'Balázs Orbán',
    textSK: 'Politológ a šéf poradcov predsedu vlády Maďarska',
    textEN: 'Political scientist and head of advisers to the Prime Minister of Hungary',
  },
  {
    image: './speaker-images/10sp-drulak.jpg',
    name: 'Prof. Petr Drulák',
    textSK: 'Český profesor medzinárodných vzťahov, bývalý námestník ministerstva zahraničných vecí ČR a bývalý veľvyslanec vo Francúzsku',
    textEN: 'Czech professor of international relations, former deputy of the Ministry of Foreign Affairs of the Czech Republic and former ambassador to France'
  },
  {
    image: './speaker-images/11sp-palko.png',
    name: 'Vladimír Palko',
    textSK: 'Bývalý minister vnútra a konzervatívny publicista',
    textEN: 'Former Home Secretary and conservative columnist'
  },
  {
    image: './speaker-images/szymanowski.JPG',
    name: 'Prof. Maciej Szymanowski',
    textSK: 'Riaditeľ Inštitútu poľsko-maďarskej spolupráce Wacława Felczaka',
    textEN: 'Director of Wacław Felczak Institute of Polish-Hungarian Cooperation'
  },
  {
    image: './speaker-images/turk.jpg',
    name: 'Prof. Žiga Turk',
    textSK: 'Bývalý minister pre rast a minister školstva, vedy, kultúry a športu',
    textEN: 'Former Minister for Growth and Minister for Education, Science, Culture and Sport'
  },
  {
    image: './speaker-images/fantini.jpg',
    name: 'Alvino-Mario Fantini',
    textSK: 'Šéfredaktor časopisu The European Conservative journal',
    textEN: 'Editor-in-Chief of The European Conservative journal'
  },
]};
