import React, { useCallback } from 'react';
import classnames from 'classnames';

import './tab-title.scss';

type Props = {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  selectedTab: number;
};

export const TabTitle: React.FC<Props> = ({ title, setSelectedTab, selectedTab, index }) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <li className="TabTitle">
      <button
        className={classnames('TabTitle-button', {
          'TabTitle-button--clicked': selectedTab === index,
        })}
        onClick={onClick}
      >
        {title}
      </button>
    </li>
  );
};
