import React, { useCallback, useRef } from 'react';

import { ReactComponent as ExpandMoreArrow } from './../../assets/expand-more-arrow.svg';

import './banner.scss';
import classNames from 'classnames';

export const Banner: React.FC<{ language: 'en' | 'sk' }> = ({ language }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const collapsibleRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = React.useState<number>(0);

  const handleToggle = useCallback(() => {
    if (collapsibleRef.current) {
      setIsExpanded(!isExpanded);
      setHeight(collapsibleRef.current.clientHeight);
    }
  }, [isExpanded]);

  const currentHeight = isExpanded ? height : 0;

  return (
    <div className="Banner">
      <span className="Banner-text">
        {language === 'en'
          ? 'Conservative Law Conference - November 12th 2024'
          : 'Konzervatívna právnická konferencia - 12. november 2024'}
      </span>
      <div
        className={classNames('Banner-listItem', {
          'Banner-listItem--collapse': isExpanded,
        })}
        onClick={handleToggle}
      >
        <div className="Banner-collapse" style={{ height: currentHeight + 'px' }}>
          <div className="Banner-body" ref={collapsibleRef}>
            <div className="Banner-bodyTextContainer">
              <span className="Banner-bodyText">
                {language === 'en'
                  ? 'The Ladislav Hanus Institute in cooperation with the Faculty of Law of Comenius University organizes the second edition of the Conservative Law Conference with the participation of domestic and foreign guests.'
                  : 'Inštitút Ladislava Hanusa v spolupráci s Právnickou fakultou Univerzity Komenského organizuje druhý ročník Konzervatívnej právnickej konferencie za účasti domácich a zahraničných hostí.'}
              </span>
            </div>
            <a href="https://www.slh.sk/pravnicka-konferencia">
              <img
                className="Banner-lawConferenceLogo"
                alt="Conservative Law Conference"
                src="./kpk.webp"
              />
            </a>
          </div>
        </div>
        <div className="Banner-buttonContainer">
          <ExpandMoreArrow
            className={classNames('Banner-expandButton', {
              'Banner-expandButton--expanded': isExpanded === true,
            })}
          />
        </div>
      </div>
    </div>
  );
};
