export type GoalData = {
  id: string;
  text: string;
};

export type Goal = {
  header: string;
  data: GoalData[];
};

export const GOALS_DATA_SK: Goal = {
  header: 'Ciele',
  data: [
    {
      id: 'Inšpirovať',
      // text: 'Priviesť do Bratislavy európske konzervatívne osobnosti z politiky a kultúry, aby nám ponúkli inšpiráciu a pomohli prehĺbiť poznanie našich koreňov a spoločensko-politických princípov, ktoré sa od nich odvodzujú.',
      text: 'Inšpirovať a formovať konzervatívnu scénu v politike i verejnom živote myšlienkami výnimočných rečníkov, vzájomne zdieľať osvedčené postupy a riešenia súčasných problémov.',
    },
    {
      id: 'Zjednocovať',
      // text: 'Vytvoriť priestor a zázemie na stretnutie konzervatívnych lídrov Slovenska z oblasti politiky, cirkví, občianskej spoločnosti, akadémie, biznisu, kultúry a médií. V situácii, keď v Európe čelíme silnejúcim snahám vytlačiť posledné zbytky kresťanského dedičstva na spoločenský okraj, je potrebné usilovať sa o spoluprácu a jednotu v podstatných veciach. Konzervatívny summit má nadstranícky charakter a je jedinečnou príležitosťou na budovanie dobrých vzťahov a atmosféry spolupráce v konzervatívnom spektre.',
      text: 'Zjednocovať sa v zásadných otázkach prostredníctvom stretnutia s významnými konzervatívnymi osobnosťami a posilniť národnú a medzinárodnú spoluprácu pri uplatňovaní kresťanských hodnôt a overených konzervatívnych princípov vo verejnom živote, a tiež chrániť spoločnosť pred úpadkom a zhubnými ideológiami.',
    },
    {
      id: 'Povzbudiť',
      // text: 'Podporiť odhodlanie a odvahu presadzovať konzervatívne hodnoty. To, že dnes kresťanský pohľad na život a človeka čelí ekonomicko-politicko-mediálnej presile v Európe, nie je dôvod na rezignáciu, ale naopak na plnšie uvedomenie si poslania rozvíjať a chrániť kresťanské dedičstvo.',
      text: 'Povzbudiť k odvahe presadzovať idey a riešenia, ktoré sú v súlade s integrálnym kresťanským pohľadom na človeka a spoločnosť, sociálnym učením Cirkvi, ochranou a podporou ľudskej dôstojnosti, života, manželstva medzi mužom a ženou, prirodzenej rodiny, základných občianskych a náboženských slobôd a zdravého rozumu pri zodpovednom spravovaní zverených dobier.',
    },
  ],
};

export const GOALS_DATA_EN: Goal = {
  header: 'Goals',
  data: [
    {
      id: 'Inspire',
      // text: 'To bring European conservative personalities from politics and culture to Bratislava to offer us inspiration and help deepen the knowledge of our roots and the socio-political principles that derive from them.',
      text: 'To inspire and shape the conservative scene in politics and public life with the ideas of exceptional speakers, mutually share best practices and solutions to current problems.',
    },
    {
      id: 'Unite',
      // text: 'To create a space and background for a meeting of conservative leaders of Slovakia from the fields of politics, churches, civil society, academia, business, culture and media. In a situation where in Europe we are faced with increasing efforts to push the last remnants of the Christian heritage to the social margins, it is necessary to strive for cooperation and unity in essential matters. The conservative summit has a non-partisan character and is a unique opportunity to build good relations and an atmosphere of cooperation in the conservative spectrum.',
      text: 'To unite on fundamental issues by meeting with prominent conservative personalities and to strengthen national and international cooperation in applying Christian values and proven conservative principles in public life, and also to protect society from decay and pernicious ideologies.',
    },
    {
      id: 'Encourage',
      // text: 'To support the determination and courage to promote conservative values. The fact that today the Christian view of life and man faces economic-political-media superiority in Europe is not a reason for resignation, but on the contrary for a fuller awareness of the mission to develop and protect the Christian heritage.',
      text: 'To encourage courage to promote ideas and solutions that are in line with the integral Christian view of man and society, the social teaching of the Church, the protection and support of human dignity, life, marriage between a man and a woman, the natural family, basic civil and religious freedoms and common sense in the responsible management of entrusted goods.',
    },
  ],
};
