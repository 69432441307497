import { GalleryData, GalleryImage } from '../../types/gallery';

export const GALLERY_DATA_SK: GalleryData = {
  header: 'Galéria',
  // subHeader: 'Konzervatívny summit 2023',
  imageTab: 'Foto',
  videoTab: 'Video',
};

export const GALLERY_DATA_EN: GalleryData = {
  header: 'Gallery',
  // subHeader: 'Conservative Summit 2023',
  imageTab: 'Pictures',
  videoTab: 'Videos',
};

export const GALLERY_IMAGES: GalleryImage[] = [
  {
    original: './2024/gallery-images/1gg.jpg',
    thumbnail: '',
  },
  {
    original: './2024/gallery-images/2gg.jpg',
    thumbnail: '',
  },
  {
    original: './2024/gallery-images/3gg.jpg',
    thumbnail: '',
  },
  {
    original: './2024/gallery-images/4gg.jpg',
    thumbnail: '',
  },
  {
    original: './2024/gallery-images/5gg.jpg',
    thumbnail: '',
  },
  {
    original: './2024/gallery-images/6gg.jpg',
    thumbnail: '',
  },
  {
    original: './2024/gallery-images/7gg.jpg',
    thumbnail: '',
  }
];