export type Sponsor = {
  name: string;
  image: string;
  link: string;
};

export const SPONSORS23: Sponsor[] = [
  {
    name: 'fides et ratio',
    image: './2023/partner-images/fides_et_ratio.jpeg',
    link: 'https://www.nfr.sk',
  },
  {
    name: 'citizen go',
    image: './2023/partner-images/cgo.png',
    link: 'https://citizengo.org',
  },
  {
    name: 'foundation for a civic hungary',
    image: './2023/partner-images/ffch.jpeg',
    link: 'https://szpma.hu/en',
  },
  {
    name: 'zdruzenie krestanskych podnikatelov a manazerov sr',
    image: './2023/partner-images/zkpmsr.png',
    link: 'https://www.zkpmsr.sk/',
  },
  {
    name: 'ordo iuris istitute',
    image: './2023/partner-images/oi.png',
    link: 'https://en.ordoiuris.pl',
  },
  {
    name: 'collegium intermarium',
    image: './2023/partner-images/ci.jpeg',
    link: 'https://collegiumintermarium.org',
  },
  {
    name: 'slh',
    image: './2023/partner-images/slh.jpg',
    link: 'https://www.slh.sk/',
  },
  {
    name: 'patrimonium sancti adalberti',
    image: './2023/partner-images/psa_zlatocervena.png',
    link: 'https://www.psazs.cz/en/',
  },
  {
    name: 'dennik standard',
    image: './2023/partner-images/standard.png',
    link: 'https://dennikstandard.sk/',
  },
  {
    name: 'dennik postoj',
    image: './2023/partner-images/dennik-postoj.png',
    link: 'https://www.postoj.sk/',
  },
  {
    name: 'european conservative',
    image: './2023/partner-images/ec.png',
    link: 'https://europeanconservative.com',
  },
];
