import React from 'react';

import { GoalData } from '../../../constants/2023/goals';
import { ReactComponent as ArrowForward } from './../../assets/arrow-forward.svg';

import './goal-card-24.scss';

export const GoalCard24: React.FC<{ goal: GoalData }> = ({ goal }) => {
  return (
    <div className="GoalCard24">
      <div className="GoalCard24-front">
        <div className="GoalCard24-id">{goal.id}</div>
        <ArrowForward width="40px" height="40px" className="GoalCard24-rightArrow" />
      </div>
      <div className="GoalCard24-back">
        <div className="GoalCard24-text">{goal.text}</div>
      </div>
    </div>
  );
};
