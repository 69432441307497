import React, { ReactNode, useCallback, useRef } from 'react';
import classNames from 'classnames';

import { ReactComponent as ExpandMoreArrow } from './../../assets/expand-more-arrow.svg';

import './collapsible-23.scss';

type Props = {
  children: ReactNode;
  contentLength: number;
  language: 'en' | 'sk';
};

interface Naming {
  'en': string;
  'sk': string;
};

const EXPANDING_NAMING: Naming = {
  en: 'Show less',
  sk: 'Ukáž menej',
};

const DECLINE_NAMING: Naming = {
  en: 'Show more',
  sk: 'Ukáž viac',
};

export const Collapsible23: React.FC<Props> = ({ children, contentLength, language }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const collapsibleRef = useRef<HTMLDivElement>(null);
  const allowedContentLegth = 80;

  const [height, setHeight] = React.useState<number>(0);

  const isSuitableForCollapse = contentLength > allowedContentLegth ? true : false;

  const handleToggle = useCallback(() => {
    if (collapsibleRef.current) {
      setIsExpanded(!isExpanded);
      setHeight(collapsibleRef.current.clientHeight);
    }
  }, [isExpanded]);

  const currentHeight = isExpanded ? height : 45;

  return (
    <div
      className={classNames('Collapsible23-listItem', {
        'Collapsible23-listItem--collapse': isExpanded,
      })}
      onClick={handleToggle}
    >
      <div className="Collapsible23-collapse" style={{ height: currentHeight + 'px' }}>
        <div Collapsible23-body ref={collapsibleRef}>
          {children}
        </div>
      </div>
      {isSuitableForCollapse && (
        <div className="Collapsible23-buttonContainer">
          <span className="Collapsible23-title">
            {isExpanded ? EXPANDING_NAMING[language] : DECLINE_NAMING[language]}
          </span>
          <ExpandMoreArrow
            className={classNames('Collapsible23-expandButton', {
              'Collapsible23-expandButton--expanded': isExpanded === true,
            })}
          />
        </div>
      )}
    </div>
  );
};
