export interface Intro {
  header: string;
}

export const INTRO_DATA_SK: Intro = {
  header: 'Úvod',
};

export const INTRO_DATA_EN: Intro = {
  header: 'Introduction',
};
