export type Organizer = {
  header: string;
  // text: string;
};

export interface AboutUs {
  header: string;
  mainOrganizer: Organizer;
  coOrganizer: Organizer;
}

export const ABOUT_US_DATA_SK: AboutUs = {
  header: 'Organizátori',
  mainOrganizer: {
    header: 'Hlavný organizátor:',
    // text: 'Inštitút Ladislava Hanusa (ILH), ktorý vychádza z radov Spoločenstva Ladislava Hanusa. Jeho víziou je spájať vieru s rozumom, rozvíjať kresťanskú kultúru a odvážne presadzovať konzervatívne princípy vo verejnom živote.',
  },
  coOrganizer: {
    header: 'Spoluorganizátor:',
    // text: 'Inštitút Rodina, ktorý sa zameriava na podporu uvažovania a diskusie o zásadných otázkach verejného života v duchu židovsko-kresťanskej tradície.',
  },
};

export const ABOUT_US_DATA_EN: AboutUs = {
  header: 'Organizers',
  mainOrganizer: {
    header: 'Main organizer:',
    // text: 'The Ladislav Hanus Institute (ILH), which is based on the Ladislav Hanus Community. His vision is to combine faith with reason, develop Christian culture and boldly promote conservative principles in public life.',
  },
  coOrganizer: {
    header: 'Co-organizer:',
    // text: 'The Rodina Institute, which focuses on supporting reflection and discussion on fundamental issues of public life in the spirit of the Judeo-Christian tradition.',
  },
};
