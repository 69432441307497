import React from 'react';
import { Speaker } from '../../../constants/2022/speakers';

import { Collapsible23 } from '../collapsible-23/collapsible-23';

import './personal-card-24.scss';

export const PersonalCard24: React.FC<{ speaker: Speaker; language: 'en' | 'sk' }> = ({
  speaker,
  language,
}) => {
  return (
    <div className="PersonalCard24">
      <div className="PersonalCard24-header">
        <img
          className="PersonalCard24-image"
          alt={speaker.name}
          src={process.env.PUBLIC_URL + speaker.image}
        />
        <div className="PersonalCard24-nameContainer">
          <div className="PersonalCard24-name" data-text={speaker.name}>
            <span>{speaker.name}</span>
          </div>
        </div>
      </div>
      <div className="PersonalCard24-text">
        <Collapsible23
          language={language}
          contentLength={language === 'en' ? speaker.textEN.length : speaker.textSK.length}
        >
          <span>{language === 'en' ? speaker.textEN : speaker.textSK}</span>
        </Collapsible23>
      </div>
    </div>
  );
};
