import React from 'react';
import { Speaker } from '../../../constants/2022/speakers';

import './personal-card.scss';

export const PersonalCard: React.FC<{ speaker: Speaker; language: 'en' | 'sk' }> = ({
  speaker,
  language,
}) => {
  return (
    <div className="PersonalCard">
      <div className="PersonalCard-header">
        <img
          className="PersonalCard-image"
          alt={speaker.name}
          src={process.env.PUBLIC_URL + speaker.image}
        />
        <div className="PersonalCard-nameContainer">
          <div className="PersonalCard-name">{speaker.name}</div>
        </div>
      </div>
      <div className="PersonalCard-text">{language === 'en' ? speaker.textEN : speaker.textSK}</div>
    </div>
  );
};
