import { GalleryData, GalleryImage, GalleryVideo } from '../../types/gallery';

export const GALLERY_DATA_SK: GalleryData = {
  header: 'Galéria',
  subHeader: 'Konzervatívny summit 2023',
  imageTab: 'Foto',
  videoTab: 'Video',
};

export const GALLERY_DATA_EN: GalleryData = {
  header: 'Gallery',
  subHeader: 'Conservative Summit 2023',
  imageTab: 'Pictures',
  videoTab: 'Videos',
};

export const GALLERY_IMAGES: GalleryImage[] = [
  {
    original: './2023/gallery-images/1g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/2g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/3g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/4g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/5g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/6g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/7g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/8g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/9g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/10g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/11g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/12g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/13g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/14g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/15g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/16g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/17g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/18g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/19g.jpg',
    thumbnail: '',
  },
  {
    original: './2023/gallery-images/20g.jpg',
    thumbnail: '',
  },
];

export const GALLERY_VIDEOS: GalleryVideo[] = [
  {
    source: 'https://www.youtube.com/embed/XZR5r0fePqM?si=6uq3cO2qXZoJgfgL',
    title: '3. Konzervatívny summit',
  },
  {
    source: 'https://www.youtube.com/embed/jiwFjB-8fyE?si=v_z8VwUUADu_XNDr',
    title: '3. Konzervatívny summit - Poslanie konzervatívnych médií',
  },
  {
    source: 'https://www.youtube.com/embed/nitmA4dNzZk?si=G1_V0iKmtGbdl6CG',
    title: '3. Konzervatívny summit - Ako čeliť progresivizmu (krátke a úderné rečnícke vstupy)',
  },
  {
    source: 'https://www.youtube.com/embed/IVea6Pp0x8U?si=4w34Wk0WPVKcFbop',
    title: '3. Konzervatívny summit - Rodinná politika',
  },
  {
    source: 'https://www.youtube.com/embed/B0IJJrXvB4M?si=CLWp7ZQIrtrLy7eG',
    title: '3. Konzervatívny summit - Hospodárska politika',
  },
  {
    source: 'https://www.youtube.com/embed/mo7TDkUkIg0?si=O2HjvnoBCyQa_puz',
    title: '3. Konzervatívny summit - Európske voľby a konzervatívci',
  },
  {
    source: 'https://www.youtube.com/embed/1fOj0tuLOPA?si=gwlNOdCEd95tPzF8',
    title: '3. Konzervatívny summit - Parlamentné voľby',
  },
  {
    source: 'https://www.youtube.com/embed/43kLX9KJZcw?si=3-fikEkFjx_dgAAL',
    title: 'Konzervatívny summit 2023 - Príležitosti a hrozby pre konzervatívcov v strednej Európe',
  },
];
