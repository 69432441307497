export type Speaker = {
  image: string;
  name: string;
  textSK: string;
  textEN: string;
};

export interface SpeakerData {
  headerSK: string;
  headerEN: string;
  speakers: Speaker[];
}

export const SPEAKER_DATA: SpeakerData = {
  headerSK: 'Potvrdení rečníci',
  headerEN: 'Confirmed speakers',
  speakers: [
    {
      image: './2023/speakers-images/dreher.jpg',
      name: 'Rod Dreher',
      textSK:
        'Konzervatívny mysliteľ, spisovateľ a publicista, autor kníh Benediktova voľba a Neži v lži. Jeho kniha Benediktova voľba sa považuje za najdôležitejšiu knihu dekády (David Brooks) a jeho posledná kniha Neži v lži, v ktorej pre americké publikum prerozprával skúsenosti disidentov v boji s komunistickou totalitou sa stala na Amazone celosvetovým bestsellerom. Aktuálne pôsobí v Budapešti ako člen Danube Institute.',
      textEN:
        "Conservative thinker, writer and columnist, author of the books Benedict's Option and Live Not by Lies. His book Benedict's Option is considered the most important book of the decade (David Brooks), and his last book, Live Not by Lies, in which he retold the experiences of dissidents in the fight against communist totality for an American audience, became a worldwide bestseller on Amazon. He currently works in Budapest as a member of the Danube Institute.",
    },
    {
      image: './2023/speakers-images/kugler.jpeg',
      name: 'Gudrun Kugler',
      textSK:
        'Teologička, právnička a poslankyňa rakúskeho parlamentu (OVP), obhajkyňa prirodzeného zákona vo verejnom živote.',
      textEN:
        'Theologian, lawyer and member of the Austrian Parliament (OVP), defender of natural law in public life.',
    },
    {
      image: './2023/speakers-images/jochova.jpeg',
      name: 'Jana Jochová',
      textSK:
        'Konzervatívna publicistka a aktivistka, predsedníčka Aliance pro rodinu. V posledných mesiacoch zvádzala inšpirujúci občiansky zápas za manželstvo muža a ženy.',
      textEN:
        'Conservative columnist and activist, chairwoman of the Alliance for the Family. In recent months, she has waged an inspiring civil struggle for the marriage of a man and a woman.',
    },
    {
      image: './2023/speakers-images/kwasnievski.jpeg',
      name: 'Jerzy Kwaśniewski',
      textSK:
        'Predseda správnej rady a spoluzakladateľ inštitútu pre právnu kultúru Ordo Iuris. Venuje sa téme ľudských práv a slobôd, rodinnému právu a právam detí, zastupuje občanov pred národnými a medzinárodnými súdmi a tribunálmi. Magazín Politico ho zaradil medzi 28 najvplyvnejších ľudí v Európe.',
      textEN:
        "Chairman of the board and co-founder of the Institute for Legal Culture Ordo Iuris. He deals with the topic of human rights and freedoms, family law and children's rights, represents citizens before national and international courts and tribunals. Politico magazine ranked him among the 28 most influential people in Europe.",
    },
    {
      image: './2023/speakers-images/miller.png',
      name: 'Calum Miller',
      textSK:
        'Etik a filozof, prednášajúci na Oxfordskej univerzite na tému ochrany ľudského života. Vo svojom výskume sa venuje téme aktuálnej praxi vykonávania potratov.  Okrem akademického a mediálneho prostredia pôsobí ako lekár.',
      textEN:
        'Ethicist and philosopher, lecturer at Oxford University on the subject of the protection of human life. In his research, he deals with the topic of the current practice of performing abortions. In addition to the academic and media environment, he works as a doctor.',
    },
    {
      image: './2023/speakers-images/palko.jpg',
      name: 'Vladimír Palko',
      textSK:
        'Bývalý slovenský minister vnútra a kresťanskodemokratický politik, publicista, autor knihy „Levy prichádzajú“ preloženej do viacerých európskych jazykov a ocenenej pápežom Benediktom XVI.',
      textEN:
        'Former Slovak Minister of the Interior and Christian Democratic politician, publicist, author of the book "Levy prichádzajú" translated into several European languages ​​and awarded by Pope Benedict XVI.',
    },
    {
      image: './2023/speakers-images/balsinek.jpeg',
      name: 'Dalibor Balšínek',
      textSK:
        'Šéfredaktor portálu Echo 24. Portál Echo 24 patrí k najdôležitejším konzervatívnym médiam v ČR a je inšpiráciou aj pre konzervatívne mediálne prostredie na Slovensku.',
      textEN:
        'Editor-in-chief of the Echo 24 portal. The Echo 24 portal is one of the most important conservative media in the Czech Republic and is also an inspiration for the conservative media environment in Slovakia.',
    },
    {
      image: './2023/speakers-images/daniska.jpeg',
      name: 'Jaroslav Daniška',
      textSK: 'Šéfredaktor denníka Štandard.',
      textEN: 'Editor-in-chief of Štandard daily.',
    },
    {
      image: './2023/speakers-images/hanus.jpeg',
      name: 'Martin Hanus',
      textSK: 'Šéfredaktor denníka Postoj.',
      textEN: 'Editor-in-chief of Postoj daily.',
    },
    {
      image: './2023/speakers-images/faktor.jpeg',
      name: 'Pavol Faktor',
      textSK: 'Prezident združenia kresťanských podnikateľov a manažérov SR.',
      textEN:
        'President of the Association of Christian Entrepreneurs and Managers of the Slovak Republic.',
    },
    {
      image: './2023/speakers-images/klimek.jpeg',
      name: 'Marcel Klimek',
      textSK:
        'Bývalý štátny tajomník Ministerstva financií SR, ekonóm, autor kníh a koncepcií Nemecký štandard pre Slovensko, Potravinová miliarda pre Slovensko, Chorobopis a liečba slovenského zdravotníctva.',
      textEN:
        'Former State Secretary of the Ministry of Finance of the Slovak Republic, economist, author of books and concepts German standard for Slovakia, Food billion for Slovakia, Sickness register and treatment of the Slovak healthcare system.',
    },
    {
      image: './2023/speakers-images/kremsky.png',
      name: 'Peter Kremský',
      textSK: 'Predseda hospodárskeho výboru NR SR, OĽaNO.',
      textEN:
        'Chairman of the Economic Committee of the National Assembly of the Slovak Republic, OĽaNO.',
    },
  ],
};
