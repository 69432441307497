import React from 'react';
import ReactDOM from 'react-dom';

import './backdrop.scss';

export const Backdrop: React.FC<{ onClick: any }> = ({ onClick }) => {
  return ReactDOM.createPortal(
    <>
      <div className="Backdrop" onClick={onClick}></div>
    </>,
    document.getElementById('backdrop-hook')!,
  );
};
