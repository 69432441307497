import { useEffect, useState } from 'react';
import { subHours } from 'date-fns';

const getPositiveOrZeroCountValue = (number: number) => {
  return Math.sign(number) === 1 ? number : 0;
};

export const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [
    getPositiveOrZeroCountValue(days),
    getPositiveOrZeroCountValue(hours),
    getPositiveOrZeroCountValue(minutes),
    getPositiveOrZeroCountValue(seconds),
  ];
};

export const useCountdown = (targetDate: string) => {
  const TIMEZONE_CORRECTION = 2;
  const DAY_TIME_CORRECTION = -9;
  const correctedCountDownDate = subHours(
    new Date(targetDate),
    TIMEZONE_CORRECTION + DAY_TIME_CORRECTION,
  );
  const countDownDate = correctedCountDownDate.getTime();
  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};
