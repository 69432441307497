export type TimeSlot = {
  intervalSK: string;
  intervalEN: string;
  descriptionSK: string;
  descriptionEN: string;
};

export interface Program {
  headerSK: string;
  headerEN: string;
  timeSlots: TimeSlot[];

  endNoteEN?: string;
  endNoteSK?: string;
}

export const PROGRAM_DATA: Program = {
  headerSK: 'Predbežný program',
  headerEN: 'Preliminary program',
  timeSlots: [
    {
      intervalSK: '08:00 – 08:45',
      intervalEN: '08:00am – 08:45am',
      descriptionSK: 'Registrácia',
      descriptionEN: 'Registration',
    },
    {
      intervalSK: '09:00 – 09:10',
      intervalEN: '09:00am – 09:10am',
      descriptionSK: 'Privítanie: Organizátori (Spoločenstvo Ladislava Hanusa, Inštitút Rodina a Collegium Intermarium)',
      descriptionEN: 'Welcome: Organizers (Ladislav Hanus Society, Rodina Institute and Collegium Intermarium)',
    },
    {
      intervalSK: '09:10 – 09:30',
      intervalEN: '09:10am – 09:30am',
      descriptionSK: 'Úvodné príhovory predstaviteľov slovenskej vládnej koalície',
      descriptionEN: 'Opening speeches of representatives of the Slovak government coalition',
    },
    {
      intervalSK: '09:30 – 10:30',
      intervalEN: '09:30am – 10:30am',
      descriptionSK: 'Hlavný prejav a následná diskusia: Péter Szijjártó',
      descriptionEN: 'Keynote speech and subsequent discussion: Péter Szijjártó',
    },
    {
      intervalSK: '10:30 – 10:45',
      intervalEN: '10:30am – 10:45am',
      descriptionSK: 'Prestávka',
      descriptionEN: 'Break',
    },
    {
      intervalSK: '10:45 – 12:00',
      intervalEN: '10:45am – 12:00pm',
      descriptionSK: 'Politický panel',
      descriptionEN: 'Political panel',
    },
    {
      intervalSK: '12:00 – 14:00',
      intervalEN: '12:00pm – 02:00pm',
      descriptionSK: 'Obedná prestávka',
      descriptionEN: 'Lunch break',
    },
    {
      intervalSK: '14:00 – 15:15',
      intervalEN: '02:00pm – 03:15pm',
      descriptionSK: 'Kultúrny panel',
      descriptionEN: 'Cultural panel',
    },
    {
      intervalSK: '15:15 – 15:45',
      intervalEN: '03:15pm – 03:45pm',
      descriptionSK: 'Prestávka',
      descriptionEN: 'Break',
    },
    {
      intervalSK: '15:45 – 17:00',
      intervalEN: '03:45pm – 05:00pm',
      descriptionSK: 'Panel o ľudských právach',
      descriptionEN: 'Human Rights Panel',
    },
    {
      intervalSK: '17:00 – 17:30',
      intervalEN: '05:00pm – 05:30pm',
      descriptionSK: 'Prestávka',
      descriptionEN: 'Break',
    },
    {
      intervalSK: '17:30 – 18:45',
      intervalEN: '05:30pm – 06:45pm',
      descriptionSK: 'Panel o politickom líderstve (k 100. výročiu narodenia Antona Neuwirtha)',
      descriptionEN: 'Panel on Political Leadership (on the 100th anniversary of the birth of Anton Neuwirth)',
    },
    {
      intervalSK: '18:45 – 19:00',
      intervalEN: '06:45pm – 07:00pm',
      descriptionSK: 'Záver summitu',
      descriptionEN: 'End of the summit',
    },
    {
      intervalSK: '19:00',
      intervalEN: '07:00pm',
      descriptionSK: 'Recepcia a neformálne diskusie',
      descriptionEN: 'Reception and informal discussions',
    }
  ],
  // endNoteEN: '*Unfortunately, Ryszard Legutko apologized due to serious health problems',
  // endNoteSK: '*Ryszard Legutko sa žiaľ pre závažné zdravotné problémy ospravedlnil',
};
