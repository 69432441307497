import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import './side-drawer.scss';

export const SideDrawer: React.FC<{ onClick: any; show: boolean; children: any }> = ({
  onClick,
  show,
  children,
}) => {
  const content = (
    <>
      <CSSTransition in={show} timeout={200} classNames="slide-in-left" mountOnEnter unmountOnExit>
        <aside className="SideDrawer" onClick={onClick}>
          {children}
        </aside>
      </CSSTransition>
    </>
  );

  return ReactDOM.createPortal(content, document.getElementById('drawer-hook')!);
};
