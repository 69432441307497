export type Sponsor = {
  name: string;
  image: string;
  link: string;
};

export const SPONSORS: Sponsor[] = [
  {
    name: 'ordo iuris istitute',
    image: './partner-images/oi.png',
    link: 'https://en.ordoiuris.pl',
  },
  {
    name: 'danube institute',
    image: './partner-images/danube_institute.jpg',
    link: 'https://danubeinstitute.hu/',
  },
  {
    name: 'ecpm',
    image: './partner-images/ecpm.png',
    link: 'https://www.ecpm.info/',
  },
  {
    name: 'dennik standard',
    image: './partner-images/standard.png',
    link: 'https://dennikstandard.sk/',
  },
  {
    name: 'dennik postoj',
    image: './partner-images/dennik-postoj.png',
    link: 'https://www.postoj.sk/',
  },
  {
    name: 'ministerstvo práce, sociálnych vecí a rodiny Slovenskej republiky',
    image: './partner-images/mpsvar.jpg',
    link: 'https://www.employment.gov.sk/sk/',
  },
  {
    name: 'Nadácia Slovakia Christiana',
    image: './partner-images/slovakia_christiana.png',
    link: 'https://slovakiachristiana.sk/',
  },
  {
    name: 'Veľvyslanectvo Maďarska Bratislava',
    image: './partner-images/hu_amb.jpg',
    link: 'https://pozsony.mfa.gov.hu/svk',
  },


  // {
  //   name: 'fides et ratio',
  //   image: './partner-images/fides_et_ratio.jpeg',
  //   link: 'https://www.nfr.sk',
  // },
  // {
  //   name: 'lexmann',
  //   image: './partner-images/lexmann.jpeg',
  //   link: 'https://lexmann.eu/',
  // },
  
  
  // {
  //   name: 'obyčajní ľudia a nezávislé osobnosti',
  //   image: './partner-images/olano.png',
  //   link: 'https://www.obycajniludia.sk/',
  // },
  // {
  //   name: 'patrimonium sancti adalberti',
  //   image: './partner-images/psa_zlatocervena.png',
  //   link: 'https://www.psazs.cz/en/',
  // },
  // {
  //   name: 'slh',
  //   image: './partner-images/slh.jpg',
  //   link: 'https://www.slh.sk/',
  // },
  
  // {
  //   name: 'european conservative',
  //   image: './partner-images/ec.png',
  //   link: 'https://europeanconservative.com',
  // },
];
