import { GalleryData, GalleryImage, GalleryVideo } from "../../types/gallery";

export const GALLERY_DATA_SK: GalleryData = {
  header: 'Galéria',
  subHeader: 'Konzervatívny summit 2022',
  imageTab: 'Foto',
  videoTab: 'Video',
};

export const GALLERY_DATA_EN: GalleryData = {
  header: 'Gallery',
  subHeader: 'Conservative Summit 2022',
  imageTab: 'Pictures',
  videoTab: 'Videos',
};

export const GALLERY_IMAGES: GalleryImage[] = [
  {
    original: './2022/gallery-images/1g.jpg',
    thumbnail: './2022/gallery-images/1g-tn.jpg',
  },
  {
    original: './2022/gallery-images/2g.jpg',
    thumbnail: './2022/gallery-images/2g-tn.jpg',
  },
  {
    original: './2022/gallery-images/3g.jpg',
    thumbnail: './2022/gallery-images/3g-tn.jpg',
  },
  {
    original: './2022/gallery-images/4g.jpg',
    thumbnail: './2022/gallery-images/4g-tn.jpg',
  },
  {
    original: './2022/gallery-images/5g.jpg',
    thumbnail: './2022/gallery-images/5g-tn.jpg',
  },
];

export const GALLERY_VIDEOS: GalleryVideo[] = [
  {
    source: 'https://www.youtube.com/embed/totrkVN9dQg?si=T7QOuIKgtS2npgS_',
    title: 'Konzervatívny summit 2022',
  },
];