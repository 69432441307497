import React from 'react';
import { GOALS_DATA_EN, GOALS_DATA_SK, GoalData } from '../../../constants/2024/goals';
import { GoalCard24 } from './goal-card-24';

import './goals-24.scss';
import { FadeInWhenVisible } from '../../../utils/fade-in';

export const Goals24: React.FC<{ language: 'en' | 'sk' }> = ({ language }) => {
  const goalData = language === 'en' ? GOALS_DATA_EN : GOALS_DATA_SK;

  return (
    // <FadeInWhenVisible>
      <div className="Goals24" id="cs-goals">
        <div className="Goals24-contentContainer">
          <h1 className="Goals24-header">{goalData.header}</h1>
          <div className="Goals24-content">
            <div className="Goals24-list">
              {goalData.data.map((goal: GoalData) => (
                <GoalCard24 goal={goal} />
              ))}
            </div>
          </div>
        </div>
      </div>
    // </FadeInWhenVisible>
  );
};
