export type RegistrationData = {
  header: string;
  text?: string;
  buttonText?: string;
};

export interface Registration {
  header: string;
  date: RegistrationData;
  locality: RegistrationData;
  registration: RegistrationData;
  dressCode: RegistrationData;
}

export const REGISTRATION_DATA_SK: Registration = {
  header: 'Registrácia',
  date: {
    header: 'Termín',
    text: '11. novembra 2024, 8h - 21h',
  },
  locality: {
    header: 'Miesto',
    text: 'Historická budova Národnej rady, Župné námestie 11, Bratislava',
  },
  registration: {
    header: 'Registrácia',
    buttonText: 'Zaregistrujte sa tu!',
  },
  dressCode: {
    header: 'Dress Code',
    text: 'formal',
  },
};

export const REGISTRATION_DATA_EN: Registration = {
  header: 'Registration',
  date: {
    header: 'Date',
    text: '11. November 2024, 8am - 9pm',
  },
  locality: {
    header: 'Locality',
    text: 'Historic building of the National Council, Župné námestie 11, Bratislava',
  },
  registration: {
    header: 'Registration',
    buttonText: 'Register here!',
  },
  dressCode: {
    header: 'Dress Code',
    text: 'formal',
  },
};
