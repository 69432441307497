import { GalleryData, GalleryImage, GalleryVideo } from '../../types/gallery';

export const GALLERY_DATA_SK: GalleryData = {
  header: 'Galéria',
  subHeader: 'Konzervatívny summit 2021',
  imageTab: 'Foto',
  videoTab: 'Video',
};

export const GALLERY_DATA_EN: GalleryData = {
  header: 'Gallery',
  subHeader: 'Conservative Summit 2021',
  imageTab: 'Pictures',
  videoTab: 'Videos',
};

export const GALLERY_IMAGES: GalleryImage[] = [
  {
    original: './2021/gallery-images/12g.jpg',
    thumbnail: './2021/gallery-images/12g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/2g.jpg',
    thumbnail: './2021/gallery-images/2g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/3g.jpg',
    thumbnail: './2021/gallery-images/3g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/4g.jpg',
    thumbnail: './2021/gallery-images/4g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/5g.jpg',
    thumbnail: './2021/gallery-images/5g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/6g.jpg',
    thumbnail: './2021/gallery-images/6g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/7g.jpg',
    thumbnail: './2021/gallery-images/7g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/8g.jpg',
    thumbnail: './2021/gallery-images/8g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/9g.jpg',
    thumbnail: './2021/gallery-images/9g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/10g.jpg',
    thumbnail: './2021/gallery-images/10g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/11g.jpg',
    thumbnail: './2021/gallery-images/11g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/1g.jpg',
    thumbnail: './2021/gallery-images/1g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/13g.jpg',
    thumbnail: './2021/gallery-images/13g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/14g.jpg',
    thumbnail: './2021/gallery-images/14g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/15g.jpg',
    thumbnail: './2021/gallery-images/15g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/16g.jpg',
    thumbnail: './2021/gallery-images/16g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/17g.jpg',
    thumbnail: './2021/gallery-images/17g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/18g.jpg',
    thumbnail: './2021/gallery-images/18g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/19g.jpg',
    thumbnail: './2021/gallery-images/19g-thumbnail.jpg',
  },
  {
    original: './2021/gallery-images/20g.jpg',
    thumbnail: './2021/gallery-images/20g-thumbnail.jpg',
  },
];

export const GALLERY_VIDEOS: GalleryVideo[] = [
  {
    source: 'https://www.youtube.com/embed/SdCr2IGnX-A?si=m9Ee8B5Ofb404j-W',
    title: 'Konzervatívny summit 2021 - Úvod + hlavný prejav - Péter Szijjártó',
  },
  {
    source: 'https://www.youtube.com/embed/wYJeFP0abyo?si=PemiwjfZxrTio7et',
    title: 'Konzervatívny summit 2021 - Panel o politickom líderstve',
  },
  {
    source: 'https://www.youtube.com/embed/Adjt5f0n7Rw?si=VggciJB5amulL_El',
    title: 'Konzervatívny summit 2021 - Panel o ľudských právach',
  },
  {
    source: 'https://www.youtube.com/embed/s9EF9euC0JE?si=qTJw3a1O3GFpHXQ8',
    title: 'Konzervatívny summit 2021 - Kultúrny panel',
  },
  {
    source: 'https://www.youtube.com/embed/sJTpYr8JKJ0?si=cjA-wYZRg8IVKfE1',
    title: 'Konzervatívny summit 2021 - Politický panel',
  },
];
