import React from 'react';
import ImageGallery from 'react-image-gallery';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { GalleryData, GalleryImage, GalleryVideo } from '../../../types/gallery';
import { Tab24 } from '../tabs/tab-24';
import { Tabs24 } from '../tabs/tabs-24';

import './gallery-24.scss';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 620 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 620, min: 0 },
    items: 1,
  },
};

export const Gallery24: React.FC<{
  language: 'en' | 'sk';
  // showImage: boolean;
  // showVideo: boolean;
  images?: GalleryImage[];
  videos?: GalleryVideo[];
  data: GalleryData;
}> = ({ language, images, videos, data }) => {
  return (
    <div className="Gallery24" id="cs-gallery">
      <h1 className="Gallery24-header">{data.header}</h1>
      <div className="Gallery24-container">
        {/* <h3>{data.subHeader}</h3> */}
        <Tabs24>
          <Tab24 title={data.imageTab}>
            <div className="Gallery24-content">
              {images && (
                <ImageGallery
                  items={images}
                  showBullets={true}
                  showIndex={false}
                  showThumbnails={false}
                  lazyLoad={true}
                  showPlayButton={false}
                  autoPlay={false}
                />
              )}
            </div>
          </Tab24>
          <Tab24 title={data.videoTab}>
            <div className="Gallery24-content">
              <span>{language === 'en' ? 'coming soon...' : 'už čoskoro...'}</span>
              {/* <Carousel responsive={responsive} showDots={false}>
                {videos &&
                  videos.map((video) => (
                    <div className="Gallery-videoElement">
                      <iframe
                        width="100%"
                        height="250px"
                        src={video.source}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      />
                      <span className="Gallery-videoElementHeader">{video.title}</span>
                    </div>
                  ))}
              </Carousel> */}
            </div>
          </Tab24>
        </Tabs24>
      </div>
    </div>
  );
};
