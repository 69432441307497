export type TimeSlot = {
  interval: string;
  description: string;
};

export interface Program {
  header: string;
  timeSlots: TimeSlot[];

  endNote: string;
}

export const PROGRAM_DATA_SK: Program = {
  header: 'Predbežný program',
  timeSlots: [
    {
      interval: '08:00 – 08:45',
      description: 'Registrácia',
    },
    {
      interval: '09:00 – 09:30',
      description: 'Privítanie a úvodné príhovory',
    },
    {
      interval: '09:30 – 10:30',
      description: 'Stredoeurópsky panel politikov',
    },
    {
      interval: '10:30 – 11:00',
      description: 'Prestávka',
    },
    {
      interval: '11:00 – 12:00',
      description: 'Stredoeurópsky panel intelektuálov V4',
    },
    {
      interval: '12:00 – 13:30',
      description: 'Obedná prestávka',
    },
    {
      interval: '13:30 – 14:30',
      description:
        'Voliteľné diskusné workshopy (rodinná politika/energetická kríza/mladí konzervatívci)',
    },
    {
      interval: '14:30 – 15:00',
      description: 'Prestávka',
    },
    {
      interval: '15:00 – 16:00',
      description: 'Európsky panel',
    },
    {
      interval: '16:00 – 16:15',
      description: 'Prestávka',
    },
    {
      interval: '16:15 – 17:45',
      description: 'Panel predsedov vlád Slovenska a Poľska',
    },
    {
      interval: '17:45 – 18:00',
      description: 'Záver oficiálnej časti summitu',
    },
    {
      interval: '18:00',
      description: 'Recepcia a neformálne diskusie',
    },
  ],
  endNote: '*program sa môže meniť v závislosti od príchodu zahraničných delegácii',
};

export const PROGRAM_DATA_EN: Program = {
  header: 'Preliminary program',
  timeSlots: [
    {
      interval: '08:00am – 08:45am',
      description: 'Registration',
    },
    {
      interval: '09:00am – 09:30am',
      description: 'Welcome and introductory speeches',
    },
    {
      interval: '09:30am – 10:30am',
      description: 'Central European panel of politicians',
    },
    {
      interval: '10:30am – 11:00am',
      description: 'Break',
    },
    {
      interval: '11:00am – 12:00pm',
      description: 'Central European panel of intellectuals V4',
    },
    {
      interval: '12:00pm – 01:30pm',
      description: 'Lunch break',
    },
    {
      interval: '01:30pm – 02:30pm',
      description:
        'Optional discussion workshops (family policy/energy crisis/young conservatives)',
    },
    {
      interval: '02:30pm – 03:00pm',
      description: 'Break',
    },
    {
      interval: '03:00pm – 04:00pm',
      description: 'European panel',
    },
    {
      interval: '04:00pm – 04:15pm',
      description: 'Break',
    },
    {
      interval: '04:15pm – 05:45pm',
      description: 'Panel of Prime Ministers of Slovakia and Poland',
    },
    {
      interval: '05:45pm – 06:00pm',
      description: 'The conclusion of the official summit part',
    },
    {
      interval: '06:00pm',
      description: 'Reception and informal discussions',
    },
  ],
  endNote: '*the program may change depending on the arrival of foreign delegations',
};
