import React from 'react';

import './tab.scss';

type Props = {
  title: string;
  children: any;
};

export const Tab: React.FC<Props> = ({ children }) => {
  return (
    <div className="Tab">
      {children}
    </div>
  );
};
