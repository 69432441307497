import React from 'react';

import { PROGRAM_DATA } from '../../../constants/2023/program';

import './program-23.scss';
import classNames from 'classnames';

export const Program23: React.FC<{ language: 'en' | 'sk' }> = ({ language }) => {
  return (
    <div className="Program23" id="cs-program">
      <h1 className="Program23-header">
        {language === 'en' ? PROGRAM_DATA.headerEN : PROGRAM_DATA.headerSK}
      </h1>
      <div className="Program23-container">
        {PROGRAM_DATA.timeSlots.map((data) => (
          <table className="Program23-table" key={data.descriptionEN}>
            <tr className="Program23-slot">
              <td
                className={classNames('Program23-interval', {
                  'Program23-interval--english': language === 'en',
                })}
              >
                {language === 'en' ? data.intervalEN : data.intervalSK}
              </td>
              <td className="Program23-description">
                {language === 'en' ? data.descriptionEN : data.descriptionSK}
              </td>
            </tr>
          </table>
        ))}
        <div className="Program23-announcementContainer">
          <p>
            {/* {language === 'en'
              ? 'More information coming soon...'
              : 'Viac informácií už čoskoro...'} */}
            {language === 'en' ? PROGRAM_DATA.endNoteEN : PROGRAM_DATA.endNoteSK}
          </p>
        </div>
      </div>
    </div>
  );
};
